import Vue from 'vue'
import Router from 'vue-router'

function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `./pages/${view}.vue`)
}

Vue.use(Router);

const v3Core = loadView('_v3.3');
const v3Meta = { 
  version: 3.0,
  promo: false,
  display: {
    web_footer: 'hide',
    trends: true,
  },
  // mini: true, // <<-- @Jason - this removes the header & footer (when: true)
};


const v5Core = loadView('HomeV5');
const v5Meta = { 
  version: 5.0,
  promo: false, 
  mini: false,
  display: {
    web_footer: 'hide',
    body_class: [
      'clear'
    ],
  },
};

const router = new Router({
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition && savedPosition.y) {
			return window.scrollTo({ top: savedPosition.offsetTop, behavior: 'smooth' });
		} else {
      // off for v3 for now..
      if (this.app.$route.meta.version >= 3.0) return;
      // as you were..
			return window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	},
	routes: [

		{
			path: '*',
			name: 'not-found',
			component: loadView('404'),
		},


		{
			path: '/dashboard',
			name: 'Dashboard',
			component: loadView('Dashboard'),
      meta: { promo: false },
		},
		{
			path: '/login',
			name: 'Login',
			component: loadView('Section'),
      meta: { promo: false },
		},
		{
			path: '/deck',
			name: 'Deck',
			component: loadView('Deck'),
      meta: { promo: false, mini: true },
		},
		{
			path: '/trends/:topics?',
			redirect: {
				name: 'Analyse'
			}
		},
		{
			path: '/weekly',
			name: 'OneSub - Weekly',
			component: loadView('weekly/Weekly'),
			meta: { promo: false },
		},
		
		{
			path: '/analyse/working',
			name: 'Working',
			component: loadView('Analysing'),
      meta: { promo: false },
		},
		{
			path: '/analyse/covid',
			name: 'Analysing',
			component: loadView('AnalysingCovid'),
      meta: { promo: false },
		},
		{
			path: '/analyse/retry',
			name: 'Analysing Reload',
			component: loadView('AnalysingReload'),
      meta: { promo: false },
		},
		{
			path: '/account',
			component: loadView('Account'),
			meta: { promo: false },
			children: [
				{ path: '', component: loadView('account/Details') },
				{ path: 'communication', component: loadView('account/Communication') },
				{ path: 'threads', component: loadView('account/Threads') },
				{ path: 'pro', component: loadView('account/Pro') },
				{ path: 'manage', component: loadView('account/Manage') },
			]
		},
		{
			path: '/account/stripe/done',
			name: 'Account Stripe',
			component: loadView('AccountStripe'),
      meta: { promo: false },
		},
		{
			path: '/tools/stories',
			name: 'Tools: Story Merge',
			component: loadView('ToolsStories'),
      meta: { promo: false },
		},
		// temporary route - delete when blurBackground.vue is deleted
		{
			path: '/blur',
			name: 'Blur Background demo',
			component: loadView('BlurBackground'),
      meta: { promo: false },
    },
    // --------------- MY STORY / MY PAPER / ONEPAPER -----------------
    {
			path: '/share',
			name: 'Share, Create & Bookmark with OneSub',
			component: loadView('Share'),
      meta: { promo: false, mini: true },
    },
    {
			path: '/~:xname/:slug/:clip?',
			name: 'Create, Share & Bookmark Intelligently',
			component: loadView('Thread'),
      meta: { promo: false },
    },
    {
			path: '/~:xname',
			name: 'Create, Share & Bookmark intelligently with OneSub',
			component: loadView('ThreadList'),
      meta: { promo: false },
    },





    // --------------- HAND BUILT LANDERS -----------------
		{
			path: '/mission',
			name: 'Mission',
			component: loadView('landers/Mission'),
      meta: { promo: false },
    },
		{
			path: '/influencers',
			name: 'Influencers',
			component: loadView('Influencers'),
		},

		{
			path: '/threads*', // wildcarded for ad campaigns & OG variation /threads/clip-thread-share etc.
			name: 'Threads',
			component: loadView('Threads'),
      meta: { promo: false },
    },
    





















    // --------------- v3 - v5 Switch -- HOMEPAGE -----------------

    // MIGRATE_MODE: v5 Live
    // See also: App.vue `MIGRATE_MODE`
    
    {
			path: '/',
			name: 'OneSub',

      // component: v3Core,
      // meta: v3Meta,

			component: v5Core,
			meta: v5Meta,
		},


    // --------------- v3 - v5 Switch -- HOMEPAGE -----------------






		{
			path: '/v5',
			component: v5Core,
			meta: v5Meta,
		},

		{
			path: '/v5/:slug?',
			component: v5Core,
			meta: v5Meta,
		},
    {
			path: '/v5/:slug/:node/:card?',
			component: v5Core,
      meta: {
        ...v5Meta,
        mode: 'story', // masks the /story hardcoding during transition
      },
		},

    

    // --------------- v3 - demo namespace -----------------

    // {
		// 	path: '/v3',
		// 	name: 'v3 Core',
		// 	component: v3Core,
    //   meta: v3Meta,
    // },
    // {
		// 	path: '/v3/:mode/:slug',
		// 	name: 'v3 Slug',
		// 	component: v3Core,
    //   meta: v3Meta,
    // },
    // {
		// 	path: '/v3/:mode/:slug/:node/:card?',
		// 	name: 'v3 Node',
		// 	component: v3Core,
    //   meta: v3Meta,
    // },


		// --------------- v3 - LEGACY! -----------------
		

		{
			// path: '/story/:story/v3',
			path: '/:mode(story)/:slug/v3',
			name: 'Story v3',
			// component: loadView('StoryV3'),
      // meta: { promo: true },
			component: v3Core,
      meta: {
        ...v3Meta,
        mode: 'story', // masks the /story hardcoding during transition
      },
      // beforeEnter: (to, from, next) => {
      //   // console.log("ROUTING A: ", to, from);
      //   // next(false);
      // },
		},
		{
			// path: '/story/:story/',
			path: '/:mode(story)/:slug/',
			name: 'Story',
			// component: loadView('Story'),
      // meta: { promo: true },
			component: v3Core,
      meta: {
        ...v3Meta,
        mode: 'story', // masks the /story hardcoding during transition
      },
      // beforeEnter: (to, from, next) => {
      //   // console.log("ROUTING B: ", to, from);
      //   // next(false);
      // },
		},
		{
			// path: '/story/:story/:article',
			path: '/:mode(story)/:slug/:node/:card?',
			name: 'Article',
			// component: loadView('Article'),
      // meta: { promo: true },
			component: v3Core,
      meta: {
        ...v3Meta,
        mode: 'story', // masks the /story hardcoding during transition
      },
      // beforeEnter: (to, from, next) => {
      //   // console.log("ROUTING C: ", to, from);
      //   // next(false);
      // },
		},
		{
			path: '/section/:name',
			name: 'Section',
			component: loadView('Section'),
      meta: { promo: true },
		},
    
		// {
		// 	path: '/v3',
		// 	name: 'v3',
		// 	component: v3Core,
    //   meta: v3Meta,
		// },
		// {
		// 	path: '/v3/:mode/:slug',
		// 	name: 'v3',
		// 	component: v3Core,
    //   meta: v3Meta,
		// },
		// {
		// 	path: '/v3/:mode/:slug/:item',
		// 	name: 'v3',
		// 	component: v3Core,
    //   meta: v3Meta,
		// },
		

    // ---------------- v4 -----------------------------
    {
      path: '/recommended',
      name: 'Recommended',
      component: loadView('v4/Recommended')
    },


    // --------------- Demo/Debugging etc. -----------------
		{
			path: '/demo/v3-grid', 
			name: '100Yard Grid',
			component: loadView('debug/v3-grid'),
			meta: { promo: false, mini: true },
		},
		{
			path: '/demo/v3-components', 
			name: 'v3Components',
			component: loadView('debug/v3-components'),
			meta: { promo: false, mini: true },
		},
		{
			path: '/demo/v3-view-100', 
			name: '100Yard',
			component: loadView('debug/v3-view-100'),
			meta: { promo: false, mini: true },
		},
		{
			path: '/demo/story-layout', 
			name: 'Threads Demo',
			component: loadView('debug/StoryColumns'),
			meta: { promo: false },
		},

		{
			path: '/demo/event-depth', 
			name: 'Event Depth',
			component: loadView('debug/EventDepth'),
			meta: { promo: false },
		},
	

		// New story - to replace
		{
			path: '/demo/story-v2',
			name: 'Story v2',
			component: loadView('debug/StoryPrez'),
			meta: { promo: true },
		},
		//
		// New profile feed - to replace
		{
			path: '/demo/profile-feed',
			name: 'ProfileFeed',
			component: loadView('debug/ProfileFeed'),
			meta: { promo: true },
		},

		// New home - to replace
		{
			path: '/demo/home',
			name: 'Home',
			component: loadView('Home'),
			meta: { promo: false, mini: true },
		},
		// New home - to replace
		{
			path: '/demo/v3',
			name: 'V3 Playground',
			component: loadView('v3'),
			meta: { promo: false, mini: true },
		},
		{
			path: '/demo/dial',
			name: 'V3 Playground Dial',
			component: loadView('debug/Dial'),
			meta: { promo: false, mini: true },
		},

		{
			path: '/demo/video/:slug?',
			name: 'V4 Video',
			component: loadView('debug/Video'),
			meta: { promo: false, mini: true },
		},














		{
			path: '/demo/v4-home',
			name: 'Welcome to OneSub',
			component: loadView('HomeV4'),
			meta: { 
				promo: false, 
				mini: false,
				version: 4.5,
				display: {
					web_footer: 'hide',
				},
			},
		},


		{
			path: '/demo/v4',
			name: 'V4 Playground',
			component: loadView('debug/v4Layout'),
			meta: { 
				promo: false, 
				mini: false,
				version: 4.5,
				display: {
					web_footer: 'hide',
				},
			},
		},

		{
			path: '/demo/v4-components',
			name: 'V4 Playground Components',
			component: loadView('debug/v4Components'),
			meta: { 
				promo: false, 
				mini: false,
				version: 4.5,
				display: {
					web_footer: 'hide',
				},
			},
		},



		/* -----------------------------------------------------------------------
		v5 - News
		----------------------------------------------------------------------- */
		{
			path: '/news',
			name: 'News',
			component: v3Core,
			meta: v3Meta,
		},
		{
			path: '/demo/news',
			name: 'News',
			component: loadView('News'),
			meta: { promo: false },
			children: [
				{
					path: ':topic',
					name: 'News',
					component: loadView('NewsGrid'),
					meta: { promo: false },
				},
			]
		},

		/* -----------------------------------------------------------------------
		v5 - Story
		----------------------------------------------------------------------- */
		{
			path: '/demo/story/:slug',
			name: 'Story',
			component: loadView('Storyv5'),
			meta: v5Meta,

		},
		{
			path: '/demo/story/:slug/:node',
			name: 'Story',
			component: loadView('Storyv5'),
			meta: v5Meta,
		},

		/* -----------------------------------------------------------------------
		v5 - Topics
		----------------------------------------------------------------------- */
		{
			path: '/topics',
			name: 'Topics',
			component: loadView('Topics'),
			meta: { promo: false },
		},
		{
			path: '/topic/:name',
			name: 'Topic',
			component: loadView('Topic'),
			meta: { promo: true },
		},

		/* -----------------------------------------------------------------------
		v5 - Open
		----------------------------------------------------------------------- */
		{
			path: '/open',
			component: loadView('Open'),
			meta: { promo: false },
			children: [
				{ path: '', name: 'OneSub - Open', component: loadView('landers/Open'), meta: { pormo: false } },
				{ path: 'trends/:topics?', name: 'Analyse', component: loadView('Analyse'), meta: { promo: false } },
				{ path: 'publishers', name: 'Publishers', component: loadView('OpenPublishers'), meta: { promo: false } },
				{ path: 'status', name: 'Status', component: loadView('OpenStatus'), meta: { promo: false } },
				{ path: 'status/clean', name: 'Status Clean', component: loadView('OpenStatus'), meta: { promo: false, mini: true } },
			]
		},

		/* -----------------------------------------------------------------------
		v5 - Social - Only used for creating assets - Temporary - Do not list
		----------------------------------------------------------------------- */
		{
			path: '/demo/social-assets/topic/:topic',
			component: loadView('Social'),
			meta: { promo: false },
		},

		/* -----------------------------------------------------------------------
		v5 - Landers
		----------------------------------------------------------------------- */
		{
			path: '/hello/onesub',
			component: loadView('lander/OneSub'),
			meta: {
				promo: false,
				mini: true
			},
		},
	]
})

export default router
