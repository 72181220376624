new PerformanceObserver((list) => {
  list.getEntries().forEach((entry) => {
    let iTime = Math.round(entry.startTime) / 1000;
    console.log(`PERF: (${iTime}) -- ${entry.entryType}`);
  });
}).observe({ entryTypes: ['longtask', 'largest-contentful-paint', 'layout-shift', 'paint'] });



import Vue from 'vue'

// eslint-disable-next-line
import EventBus from './eventBus.js';

import store from './store'
import router from './router'

import dayjs from 'dayjs'
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

import pluralize from 'pluralize'

/**
 *  Global/Common V3 components.
 *  ----------------------------
 *  Anything in: ./components/_v3.3/_common will be available
 *  -----------
 *  Examples:
 *   - ./Loading.vue
 */




import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  './components/_v3.3/_common',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )


  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

/**
 *  END GLOBAL LOADER..........
 */























// import axios from 'axios';

// REMOVED (July 2023) -- slow and no-one looks at it anyway! ... if re-added, sample or exclude GoogleBot!
// import 'app/handlers/bugsnag/bugsnag'
import App from './App';

import DomPortal from 'vue-dom-portal'
Vue.use(DomPortal);

// var infiniteScroll =  require('vue-infinite-scroll');
// Vue.use(infiniteScroll);

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

import Loader from './components/Loader'
Vue.component('global-loader', Loader);

import Header from './modules/Header'
Vue.component('global-header', Header);

// import Header from './components/_v5/base/Header'
// Vue.component('global-header', Header);

import Modal from './components/Modal'
Vue.component('global-modal', Modal);

import SVG from './components/SVG'
Vue.component('global-svg', SVG);

import v5SVG from './components/_v5/SVG'
Vue.component('base-svg', v5SVG);

import Social from './components/Social'
Vue.component('global-social', Social);

// REMOVED: JM (Jul 2023) -- not sure it's used and slows Contentful Paint metrics 11ms
// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload, {
//   lazyComponent: true
// });
import './registerServiceWorker'

Vue.config.productionTip = false

// eslint-disable-next-line
import layoutEngine from './layoutEngine.js';

// eslint-disable-next-line
import dataEngine from './dataEngine.js';

// eslint-disable-next-line
import userEngine from './userEngine.js';









/**
 *  Filters (formatters)
 */

Vue.filter('number', function (value) {
  if (!value) return ''
  return new Intl.NumberFormat(navigator.language,{}).format(value);
});

Vue.filter('stat', function (value) {
  if (!value) return '-';
  if (value < 10){
    return (value == Math.round(value)) ? value : (value).toFixed(2);
  }
  if (value > 1000) {
    return (value/1000).toFixed(1) + 'k';
  }
  if (value > (1000 * 1000)) {
    return (value/(1000 * 1000)).toFixed(1) + 'm';
  }
  return new Intl.NumberFormat(navigator.language,{}).format(Math.round(value));
});

Vue.filter('round', function (value) {
  if (!value) return ''
  return new Intl.NumberFormat(navigator.language,{}).format(Math.round(value));
});

Vue.filter('abs', function (value) {
  if (!value) return '~'; 
  return new Intl.NumberFormat(navigator.language,{}).format(Math.abs(value));
});

Vue.filter('mood', function (value) {
  if (!value) return '';
  let v = Math.round(value * 100);
  if (v > 10) {
    return `Good news (${v}%)`;
  } else if (v > 0) {
    return `Positive (${v}%)`;
  } else if (v == 0) {
    return `Eh? (${v}%)`;
  } else if (v > -5) {
    return `Neutral? (${v}%)`;
  } else if (v > -25) {
    return `Negative? (${v}%)`;
  } else {
    return `Dark (${v}%)`;
  }
});

Vue.filter('rate', function (value) {
  if (!value) return ''
  return new Intl.NumberFormat(navigator.language,{maximumSignificantDigits: 2}).format(value * 100) + '%';
});

Vue.filter('pcxupdown', function (value) {
  if (!value) return ''
  if (value < 1) {
    return '▼' + Math.abs(new Intl.NumberFormat(navigator.language,{maximumSignificantDigits: 2}).format((value-1) * 100)) + '%';
  } else {
    return '▲' + new Intl.NumberFormat(navigator.language,{maximumSignificantDigits: 2}).format(value * 100) + '%';
  }
});


Vue.filter('ucwords', function (value) {
  if (!value) return ''
  return (value + '').replace(/^(.)|\s+(.)/g, function ($1) {
    return $1.toUpperCase()
  })
});

Vue.filter('ucfirst', function (value) {
  if (!value) return ''
  return value.substr(0, 1).toUpperCase() + value.substr(1);
});

Vue.filter('lcfirst', function (value) {
  if (!value) return ''
  return value.substr(0, 1).toLowerCase() + value.substr(1);
});

Vue.filter('lower', function (value) {
  return (value || '').toLowerCase();
});

Vue.filter('upper', function (value) {
  return (value || '').toUpperCase();
});

Vue.filter('hyphenated', function (value) {
  if (!value) return ''
  return (value + '').replace(/\W+/g, '-').toLowerCase();
});

Vue.filter('plural', function (i, s) {
  return pluralize(s, i);
});

Vue.filter('is_are', function (i) {
  return i == 1 ? 'is' : 'are';
});

Vue.filter('ago', function (value, bShort) {
  // exceptions within 48h
  let iThen = new Date(value);
  let iNow = new Date();
  if (iNow.getTime() - iThen.getTime() < (48 * 60 * 60 * 1000)) {
    if (iNow.getDay() == iThen.getDay()) {
      return 'Today';
    } else {
      return 'Yesterday';
    }
  }

  // or go with the usual format from dayjs
  if (bShort) {
    // dayjs.updateLocale('en', {
    //   relativeTime: {
    //     future: "in %s",
    //     past: "%s ago",
    //     s: 'just now',
    //     m: "just now",
    //     mm: "just now",
    //     h: "an hour",
    //     hh: "%dh",
    //     d: "a day",
    //     dd: "%dd",
    //     M: "a month",
    //     MM: "%dmon",
    //     y: "a year",
    //     yy: "%dy"
    //   }
    // });
  }
  return value ? dayjs().to(dayjs(value), bShort) : '';
});

Vue.filter('date', function(value) {
  return dayjs(value).format('D MMM YYYY');
});

Vue.filter('handles', function (value) {
  if (typeof value === 'undefined') {
    return '';
  }

  if (value === null) {
    return '';
  }

  return value.replace(/([~@])(\w{3,})/g, (sWhole, sMarker, sHandle) => {
    // console.log({
    //   sMarker: sMarker, 
    //   sHandle: sHandle,
    // });
    let sDomain = sMarker == '@' ? 'https://twitter.com/' : '/~';
    // NB! Remember, this won't be css-scoped!!
    return `<a class="handle" href="${sDomain}${sHandle}">${sWhole}</a>`;
  });
});







/**
 * DANGER !!! DANGER !!! DANGER !!! DANGER !!! DANGER !!! DANGER !!! DANGER !!! DANGER !!! DANGER !!! 
 * --------------------------------------------------------------------------------------------------
 * This fucks with ...$options.filters somehow.  Weirdly, it needs to be last, otherwise you end up
 * losing all the other filters!?
 */

import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' // customize this for SSR
});

import VueHtml2Canvas from 'vue-html2canvas';
// Canvas - generating social assets
Vue.use(VueHtml2Canvas);

/**
 *          ENDS --- ENDS --- ENDS --- ENDS --- ENDS --- ENDS --- ENDS --- ENDS --- ENDS
 * --------------------------------------------------------------------------------------------------
 */




window.vue = new Vue({
  store,
	router,
  render: h => h(App),
}).$mount('#app');

window.dataLayer = window.dataLayer || [];