<template>
  <div class="loader-wrapper">
    <div class="spinner spinner-1"></div>
    <div class="spinner spinner-2"></div>
    <div class="spinner spinner-3"></div>
    <div class="spinner spinner-4"></div>
    <div class="spinner spinner-5"></div>
    <div class="spinner spinner-6"></div>
    <div class="spinner spinner-7"></div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .loader-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 2em 0 2em 0;
    animation: rotation .3s ease-in-out infinite;
  }

  $transparency: 0.6;

  .spinner {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    animation: rotation .3s ease-in-out infinite;
    border: 3px solid rgba($base-background, 0);
    border-top-color: rgba($yellow-tone-3, $transparency * 1.0 );
    &.spinner-1 {
      animation: rotation-a .3s ease-in-out infinite;
      animation-delay: .1s;
      border-top-color: rgba($yellow-tone-3, $transparency * 1.5 );
    }
    &.spinner-2 {
      animation: rotation-a .4s ease-in-out infinite;
      animation-delay: .2.1s;
      border-top-color: rgba($yellow-tone-3, $transparency * 1.3 );
    }
    &.spinner-3 {
      animation: rotation-b .5s ease-in-out infinite;
      animation-delay: .3s;
      border-top-color: rgba($yellow-tone-3, $transparency * 0.8);
    }
    &.spinner-4 {
      animation: rotation-b .7s ease-in-out infinite;
      animation-delay: .1s;
      animation-direction: alternate;
      width: 1.5rem;
      height: 1.5rem;
      border-top-color: rgba($orange-tone-3, $transparency * 1.2 );
      transform: rotate(45deg);
    }
    &.spinner-5 {
      animation: rotation-d 1.1s ease-in-out infinite;
      animation-delay: .4.2s;
      // animation-direction: alternate;
      width: 1.5rem;
      height: 1.5rem;
      border-top-color: rgba($orange-tone-3, $transparency * 1.1 );
      transform: rotate(-40deg);
    }
    &.spinner-6 {
      animation: rotation-e 0.9s ease-in-out infinite;
      animation-delay: .2.4s;
      animation-direction: alternate;
      width: 1rem;
      height: 1rem;
      border-top-color: rgba($red-tone-3, $transparency * 0.9 );
      transform: rotate(128deg);
    }
    &.spinner-7 {
      animation: rotation-f 0.8s ease-in-out infinite;
      animation-delay: .5.4s;
      // animation-direction: alternate;
      width: 1rem;
      height: 1rem;
      border-top-color: rgba($red-tone-3, $transparency * 1.4 );
      transform: rotate(256deg);
    }
  }
    
  @keyframes rotation-a {
      from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}
  }
  @keyframes rotation-b {
      from {transform: rotate(90deg);}
      to {transform: rotate(450deg);}
  }
  @keyframes rotation-c {
      from {transform: rotate(-20deg);}
      to {transform: rotate(340deg);}
  }
  @keyframes rotation-d {
      from {transform: rotate(-45deg);}
      to {transform: rotate(300deg);}
  }
  @keyframes rotation-e {
      from {transform: rotate(20deg);}
      to {transform: rotate(320deg);}
  }
  @keyframes rotation-f {
      from {transform: rotate(-90deg);}
      to {transform: rotate(270deg);}
  }
</style>