<template>
  <div class="dial-container" :class="`dial-size-${size}`">
    <div class="dial-wrapper" :data-size="size" :style="{width: `${box}px`, height: `${box}px`}" v-if="a11yDialOn">
      <Progress class="dial dial-a" strokeColor="#FFDE00" :transitionDuration="speed" :radius="ringRadius(0)" :strokeWidth="stroke" :value="valSources"><div class="content"></div></Progress>
      <Progress class="dial dial-b" strokeColor="#FF5717" :transitionDuration="speed" :radius="ringRadius(1)" :strokeWidth="stroke" :value="valTopics" :style="[posStyle(1)]"><div class="content"></div></Progress>
      <Progress class="dial dial-c" strokeColor="#EE002C" :transitionDuration="speed" :radius="ringRadius(2)" :strokeWidth="stroke" :value="valTime" :style="[posStyle(2)]"><div class="content"></div></Progress>
    </div>
    <!-- if the dial is removed for accessibility, keep a little space just to maintain the round..
    <div v-else-if="size == 'tab'" class="dial-wrapper" :data-size="size" :style="{width: `${box / 2}px`, height: `${box / 2}px`}">{{size}}</div> -->

    <div class="dial-meta" v-if="['large','medium', 'fill'].includes(size) && a11yDialOn">
      <div class="inner-meta dial-mark-a"><div class="dial-marker"></div><span>Sources & Opinions</span></div>
      <div class="inner-meta dial-mark-b"><div class="dial-marker"></div><span>Countries & Topics</span></div>
      <div class="inner-meta dial-mark-c"><div class="dial-marker"></div><span>Reading Time</span></div>
    </div>
  </div>
</template>

<script>
import utils from '@assets/js/utils.js';
import layout from '@assets/js/layout.js';
import Progress from "easy-circular-progress";
export default {
  mixins: [utils, layout],
  props: {
    size: String,
    force: Object,
  },
  data() {
    return {
      sizes: {
        fill: {
          outer: 65,
          width: 13, // o/5
          gap: 2.6, // w/5
          padding: 10,
        },
        tab: {
          outer: 30,
          width: 6, // o/5
          gap: 1.2, // w/5
          padding: 6,
        },
        large: {
          outer: 50,
          width: 10, // o/5
          gap: 2, // w/5
          padding: 10,
        },
        medium: {
          outer: 30,
          width: 6,
          gap: 1.2,
          padding: 10,
        },
        small: {
          outer: 15,
          width: 4,
          gap: 1,
          padding: 10,
        },
        tiny: {
          outer: 10,
          width: 3,
          gap: 0.5,
          padding: 10,
        },
        header: {
          outer: 16,
          width: 4,
          gap: 1,
          padding: 10,
        }
      }
    };
  },
  components: {
    Progress,
  },
  computed: {
    data() {
      return this.force || this.$store.getters.getLibraryItem('dial');
    },
    valTime() {
      return Math.max(1, Math.min(100, this.data?.rings?.time?.px)) || 1;
    },
    valTopics() {
      return Math.max(1, Math.min(100, this.data?.rings?.topics?.px)) || 1;
    },
    valSources() {
      return Math.max(1, Math.min(100, this.data?.rings?.sources?.px)) || 1;
    },
    settings() {
      // console.log("?????????", this.size, this.sizes[this.size])
      return this.sizes[this.size];
    },
    box() {
      return (this.radius * 2) + (this.settings.padding * 2);
    },
    radius() {
      return this.settings.outer;
    },
    stroke() {
      return this.settings.width - this.settings.gap;
    },
    speed() {
      return 300;
    },
  },
  methods: {
    ringRadius(i) {
      return this.radius - (this.settings.width * i);
    },
    posStyle(i) {
      return {
        bottom: `${this.settings.width * i}px`,
        left: `${this.settings.width * i}px`,
      };
    }
  },
  mounted() {
    this.$store.dispatch('fetch',{ endpoint: 'dial' });
    // setInterval(() => {
    //   this.valA = Math.random() * 100;
    //   this.valB = Math.random() * 100;
    //   this.valC = Math.random() * 100;
    // }, 2500);
  }
}
</script>
<style scoped lang="scss">
  @import 'src/assets/scss/__2020_v3.scss';

  .dial-container {
    display: flex;
    align-items: center;
  }

  .dial-wrapper {
    position: relative;

    .dial {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    // acc.off
    &.tab-placeholder {
      bottom: -5px;
      right: -6px;
    }
  }

  .dial-meta {
    margin-left: 0.5em;

    .inner-meta {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
      
      .dial-marker {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5em;
      }

      &.dial-mark-a {
        .dial-marker {
          background: $yellow-tone-3;
        }

        // span {
        //   color: $yellow-tone-3;
        // }
      }

      &.dial-mark-b {
        .dial-marker {
          background: $orange-tone-3;
        }

        // span {
        //   color: $orange-tone-3;
        // }
      }

      &.dial-mark-c {
        .dial-marker {
          background: $red-tone-3;
        }

        // span {
        //   color: $red-tone-3;
        // }
      }
    }
  }

  .dial-size-fill {
    .dial-meta {
      margin-left: 1rem;
      padding-bottom: 1rem;

      .mq-sm & {
        margin-left: 0.75rem;
      }

      .inner-meta {
        font-weight: bold;
        font-size: 0.8rem;
        color: $neutral-200;

        .darkmode & {
          color: $neutral-600;
        }

        &:not(:last-child) {
          margin-bottom: 0.75em;
        }
      }
    }
  }
</style>
