import Vue from 'vue';
import store from "./store";
import router from './router'

const layoutEngine = new Vue({
  router,
  store,

	data: function () {
		return {
      aMQBridgePrevious: [],
      tResize: 0,
		}
	},
  
  computed: {
    viewMode() {
      return this.$store.hasApp;
    },
  },

	methods: {
    
    syncMQBridge() {
      
      this.notice("Syncing MQ Bridge...");

      let eMQBridge = document.getElementById('mq-bridge');

      if (!eMQBridge) {
        console.error("Failed to connect to MQ Bridge");
        return;
      }

      // this.error(getComputedStyle(eMQBridge, ':before').content);
      // this.error(getComputedStyle(eMQBridge, ':after').content);



      // device display size/mode etc..
      let sMQBridge = getComputedStyle(eMQBridge, ':before').content.replace(/^\W(.*)\W$/, '$1');
      let aMQBridge = sMQBridge.split(' ');

      // mode=app?
      if (this.$store.getters.hasApp) {
        aMQBridge.push('is-app');
      } else {
        aMQBridge.push('is-web');
      }

      // toggle out the last set and update..
      document.body.classList.remove(...this.aMQBridgePrevious);
      document.body.classList.add(...aMQBridge);

      this.aMQBridgePrevious = aMQBridge;







      // accessibility & theme (darkmode etc)
      let sMQAccess = getComputedStyle(eMQBridge, ':after').content.replace(/^\W(.*)\W$/, '$1');
      let aMQAccess = sMQAccess.split(' ');

      let sPref = this.$userEngine.getPref('layout.theme');
      this.notice(`Theme preference: ${sPref}`);

      // for now, we're only expecting ['darkmode'] or ['lightmode]
      if (sPref && (sPref != 'auto')) {
        aMQAccess[0] = sPref;
      }

      /**
       * hardcoded accessibility things.
       */

      // toggle all gamification (the dial)
      
      this.$userEngine.getPref('a11y.dial.off') ? aMQAccess.push('a11yDialOff') : null;


      document.documentElement.classList.remove(...document.documentElement.classList);
      document.documentElement.classList.add(...aMQAccess);

    }, 

    notice(){
      // console.log(`\x1B[36m LayoutEngine --`, ...arguments);
    },

    error(){
      console.error(`\x1B[36m LayoutEngine --`, ...arguments);
    },

  }, // end methods

	created() {
      // watch MQBridge mutations for body class
      // this.notice("Created");
      // this.syncMQBridge();
      // debounced resize listener.. (300ms)
      window.addEventListener('resize', () => {
        if (this.tResize) { 
          window.clearTimeout(this.tResize);
        }
        this.tResize = window.setTimeout(this.syncMQBridge, 300);
      });

      window.addEventListener('blur', this.syncMQBridge);
      window.addEventListener('focus', this.syncMQBridge);

      // also recheck when the user reloads (for layout preferences)
      this.EventBus.$on('api:account', this.syncMQBridge);
      this.EventBus.$on('user:prefs', this.syncMQBridge);
  },

  watch: {
    $route() {
      this.syncMQBridge();
    },
    viewMode() {
      this.syncMQBridge();
    },
  }
})

Object.defineProperties(Vue.prototype, {
	$layoutEngine: {
		get: function () {
			return layoutEngine
		}
	}
})
