import Vue from 'vue';
import API from 'app/axios';
import store from "./store";
import router from './router'

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */




const userEngine = new Vue({
  router,
  store,

	data: function () {
		return {
      coretick: 1000 * 0.05,  // tick interval (10th second)
      coresync: 1000 * 5,    // sync interval (10s)
      coretime: null,
      trackOnce: {}, // Plausible event throttling..
		}
	},
  
  computed: {
    get() {
      return this.$store.getAccount;
    },

    getTimers() {
      return this.$store.getters.timers;
    },
    // for the watchers
    visibleSlugs() {
      return this.$store.getters.getLibraryItem('edition_v3')?.stories?.map(a => a.slug) || [];
    },


    // wrap the user's preferences (do we need individual getters?)
    prefs() {
      return this.$store.getters.getSettings || {
        build: '-',
        edition: {
          locale: '', // set by the API & locked in thereafter (actually, ignored by axios.js at the moment..)
        },
        layout: {
          next: 'section',
          theme: 'auto',
        },
      };
    },
    
  },

	methods: {
    getPref(path){
      return path ? path.split('.').reduce((a,c) => { return a ? a[c] : null }, this.prefs) : this.prefs;
    },
    setPref(path, newValue, bForceSave){

      
      let steps = path.split('.');
      let iterator = (i) => {
        if (i < steps.length) {
          // get the current value at this step..
          // *REALLY* important to clone the object here (otherwise we're playing with references!)
          let stepValue = {...this.getPref(steps.slice(0, i).join('.')) || {}};
          // overwrite the key at this position with an iteration deeper
              stepValue[steps[i]] = iterator(i + 1);
          // and return..
          return stepValue;
        } else {
          // reached the end of the path -- overwrite what we have!
          return newValue;
        }
      };

      // start at the bottom & iterate!
      let newSettings = iterator(0);

      
      
      // ------------------------ COMMENT OUT AS ONE BLOCK! ---------------------------------

      // let sOldFingerprint = JSON.stringify(this.prefs);
      // let sNewFingerprint = JSON.stringify(newSettings);

      // console.log('Setings: (Old): ', sOldFingerprint);
      // console.log('Setings: (New): ', sNewFingerprint);

      // let bPossibleChange = (sOldFingerprint !== sNewFingerprint);

      // console.log(`Set: ${path} = ${newValue} :: (Change possible: ${bPossibleChange})`, newSettings);

      // ====================================================================================





      this.$store.commit('updateSettings', newSettings);

      // NORMALLY deep watcher will store, only when actually changed against the DB.. 
      // Deck doesn't always appear to work, somehow, through the watcher..
      if (bForceSave) {
        API.patchSettings(newSettings);
      }

      // in case anything wants to re-think
      this.EventBus.$emit('user:prefs', {
        path: path,
        to: newValue,
      });

      // return
      return newSettings;
    },
    
    navChange(to, from) {
      // console.log(`\x1B[36m UserEngine -- Nav change:`, to, from);

      // Start/Stop timers
      this.navStopTimer(from.params);
      this.navStartTimer(to.params);

      // hit the API for new slugs
      this.syncReadState([to.params.slug, to.params.node]);
    },

    syncReadState(aSlugs) {
      // filter
      aSlugs = aSlugs.filter(s => !this.$store.getters.readState(s).slug);
      aSlugs = aSlugs.filter(s => s != '...');
      aSlugs = aSlugs.filter(Boolean);

      if (!aSlugs.length) {
        return;
      }

      API.getReadStateV3(aSlugs).then(({data}) => {
        Object.values(data).forEach(a => {
          this.$store.commit('setReadState', a);
        });
      });
    },

    readState(slug) {
      return this.$store.getters.readState(slug) || {};
    },
















    /**
     * 
     *  ------------------- Core Timing Mechanism -----------------------------
     */

    navStopTimer(p){
      return this.navTimer('stop', p);
    },
    navStartTimer(p){
      return this.navTimer('start', p);
    },

    navTimer(m, p) {
      
      // create a slug for this specific object...
      let sTimerSlug = [p.mode, p.slug, p.node].filter(Boolean).join(':');

      if (!sTimerSlug){
        return;
      }

      if (![
        'story',
      ].includes(p.mode)){
        this.notice(`${m} timer: ${sTimerSlug} [NOPE - NOT A STORY]`, m, p);
        return;
      }

      this.notice(`${m} timer: ${sTimerSlug}`, m, p);
      
      // identify the timer & create if necessary
      if (!this.$store.getters.timer(sTimerSlug)){
        this.notice(`${m} created, ${sTimerSlug}`);
        this.$store.commit('timer',{
          slug: sTimerSlug,
          timer: {
            meta: p,
            seconds: 0,
            elapsed: 0,
            moments: 0,
            current: 0,
            running: false, // epoch of last running time
            periods: [], // tracking periods
            syncnxt: 0,
          },
        });
      }

      let timer = this.$store.getters.timer(sTimerSlug);

      if (m == 'start') {

        timer.running = new Date().getTime();
        timer.syncnxt = timer.running;

      } else if (m == 'stop') {

        // current elapsed time
        let elapsed = new Date().getTime() - timer.running;
        // stack 
        timer.periods.push(elapsed);
        // clear the timer 
        timer.running = false;
        timer.syncnxt = false;
      }

      this.$store.commit('timer',{
        slug: sTimerSlug,
        timer: timer,
      });

      // calcs..
      this.calcElapsed(sTimerSlug);

      // sync..
      this.sync(sTimerSlug, m);
    },

    // update a 
    calcElapsed(sTimerSlug) {
      let timer = this.$store.getters.timer(sTimerSlug);

      timer.current = timer.running ? new Date().getTime() - timer.running : 0;

      // calculate total elapsed time.
      // work around blur/focus bug
      timer.elapsed = timer.periods.reduce((a, c) => {
        // ignore any periods > 3 minutes
        let iMaxMinsPerPeriod = 3; // minutes
        let iMaxMillisecondsPerPeriod = iMaxMinsPerPeriod * 60 * 1000; // milliseconds
        return a < iMaxMillisecondsPerPeriod ? a + c : c;
      }, 0) + timer.current;

      timer.moments = timer.periods.length;
      timer.seconds = (timer.elapsed / 1000).toFixed(2);

      this.$store.commit('timer',{
        slug: sTimerSlug,
        timer: timer,
      });

      // sync? (intervals only..)
      if (timer.running && ((new Date().getTime()) > timer.syncnxt)){
        // sync: 
        // this.notice(`Update: ${sTimerSlug} (${timer.running}) -- ${timer.current}`);
        this.sync(sTimerSlug, 'interval');
      }
    },

    /**
     *  ------------------- MAIN TICK! -----------------------------
     */


    tick(){
      // this.notice(this.$store.getters.timers_runnning_keys);
      this.$store.getters.timers_runnning_keys.forEach((slug) => { this.calcElapsed(slug) });
    },

    /**
     *  ------------------- Core Timing Mechanism -----------------------------
     */


    sync(sTimerSlug, mode) {
      // no anon for now..
      if (!this.$store.getters.isLoggedIn) {
        // this.notice('Sync: (Not logged in)');
        return;
      }
      // load the template
      let payload = this.payload_template;

      // load the timer data
      let timer = this.$store.getters.timer(sTimerSlug);

      // update the syncnxt
      let lag = (new Date().getTime()) - timer.syncnxt;
      timer.syncnxt = (new Date().getTime()) + this.coresync;
      let nxt = (timer.syncnxt - new Date().getTime());
      this.$store.commit('timer',{
        slug: sTimerSlug,
        timer: timer,
      });


      // build the payload
      
      // max 90s per asset (story/article)
      // need to deal with the concept of 'focus'
      payload.time = Math.min(60 * 1.5, Math.round(timer.elapsed / 1000)); // ms ➜ s
      
      payload.story = timer.meta.slug || '';
      payload.article = timer.meta.node || '';
      // including the state (very assumed for now, might/could come from read-time :shrug:)
      payload.state = this.getSyncState(timer);

      // into which day do we correlate the data?
      payload.offset = new Date().getTimezoneOffset();
      
      this.notice(`Sync (${mode}) (Current: ${timer.current}) (${lag}/${nxt}): `, payload);
      // sync with HQ
      API.post('/track/article/read',payload).then(({data}) => {

        // map in all the updated story/article statuses..
        if (data?.status) {
          Object.values(data?.status).forEach(a => {
            this.$store.commit('setReadState', a);
          });
        }

        // update the dial
        if (data?.dial) {
          // console.log({...data?.dial?.rings?.time});
          this.$store.commit('updateDial', data?.dial);
        }
      }).catch((error) => {
        this.error(error);
      });
    },

    getSyncState(timer) {

      // load article?
      // if (timer.meta.node) {
      //   let article = this.$store.getters.getArticle(timer.meta.node);
      //   this.notice('Article: ', article);
      // }

      let seconds = timer.elapsed / 1000;
      let state = 'clicked';
      if (seconds > 30) {
        state = 'read';
      } else if (seconds > 10) {
        state = 'skimmed';
      } else if (seconds > 5) {
        state = 'glanced';
      } else {
        state = 'preview';
      }

      return state;
    },

    getSyncPayloadTemplate() {
      // platform, version, scope (browser) defaults:
      let platform = this.$store.getters.hasMobile ? 'mobile' : 'desktop';
      let version = this.$store.getters.appVersion;
      let scope = 'browser';

      // unless in app:
      if (this.$store.getters.hasApp) {
        platform = navigator.vendor.match('/Google/') ? 'android' : 'apple';
        version = this.$store.getters.hasAppVersion;
        scope = 'app';
      }

      return {
        article: null,
        story: null,
        state: null,
        time: null,
        scope: scope,
        version: version,
        platform: platform,
      }
    },

    // legacy(payload) {
    //   // Only track opt-in, real users..
    //   if (!this.loggedIn) {
    //     // console.log('Tracking: Not logged in');
    //     return;
    //   }
      
    //   // time: optional
    //   payload.time = payload.time || 0;

    //   // story: is optional
    //   let story = (payload.story === undefined) ? '' : 
    //     ( typeof payload.story === 'object' ) ? payload.story.slug : payload.story;

      

    //   /**
    //    * ----------- SEND -------
    //    */
    //   let api_payload = {
    //     article: payload.article.slug,
    //     story: story,
    //     state: payload.state,
    //     time: payload.time,
    //     scope: scope,
    //     version: version,
    //     platform: platform
    //   };
    //   // console.log("Tracking: ", api_payload);
    //   this.deviceLog("Tracking: ....");
    //   API.post('/track/article/read',api_payload).then().catch((error) => {
    //     // console.log(error);
    //   });
    // },



    trackEvent(payload) {
      // should this event be throttled to once per session? (default: yes)
      let bOnce = (payload.bOnce !== false);

      // consider throttling to once per session
      if (bOnce && this.trackOnce[payload.label]){
        // console.log(`Plausible: Throttling: '${payload.label}'`);
        return;
      }
      // record that this event has been triggered at least once
      this.trackOnce[payload.label] = true;

      // track (or stack) to Plausible
      // console.log(`Plausible: Triggering event: '${payload.label}'`);
      window.plausible(payload.label, {props: payload.props});
    },






    notice(){
      // console.log(`\x1B[36m UserEngine --`, ...arguments);
    },

    error(){
      // console.error(`\x1B[36m UserEngine --`, ...arguments);
    },



  }, // end methods

	created() {
    this.notice("Instantiated & ticking");
    this.coretime = window.setInterval(this.tick, this.coretick);
    // build the template of stuff that doesn't change per-session..
    this.payload_template = this.getSyncPayloadTemplate();
    // listen for account events (for sync, particularly)
    this.EventBus.$on('api:account', this.tick);
    // Plausible event tracking
    this.EventBus.$on('track:event', this.trackEvent);
    // globalise the setter & getter for prefs (still have to be an alpha for this to write new prefs)
    window._osxSetPref = this.setPref;
    window._osxGetPref = this.getPref;
  },

  watch: {
    $route(to, from) {
      // console.log(`\x1B[36m UserEngine -- $route change`, to, from);
      this.navChange(to, from);
    },
    visibleSlugs(to) {
      // console.log(`\x1B[36m UserEngine -- Visible Slugs Mutation:`, from, to);
      this.syncReadState(to);
    },
    prefs: {
      deep: true,
      handler: function(to, from) {
        // console.log("Settings change: ", {
        //   from: from,
        //   to: to,
        //   same: (to === from),
        //   similar: (to == from),
        //   json: (JSON.stringify(to) == JSON.stringify(from)),
        //   json_to: JSON.stringify(to),
        //   json_from: JSON.stringify(from),
        // });
        if (from.build == '-') {
          // console.log("Settings change: BUILD STOP (GUEST)");
          return; // guest.. 
        }
        if (JSON.stringify(to) == JSON.stringify(from)) {
          // console.log("Settings change: NO CHANGE");
          return; // exactly the same...
        }
        // commit to database
        // console.log("Settings change: ** PATCH **");
        API.patchSettings(to);
      }
    }
  }
})

Object.defineProperties(Vue.prototype, {
	$userEngine: {
		get: function () {
			return userEngine
		}
	}
})
