<template>
  <div class="subtitles">

    
      <div :data-line="i" class="line" v-for="(line, i) in lines" :key="i">
        <transition name="fade">
          <div v-if="i >= (lines.length - getMaxRows)">
            <transition-group name="fade">
              <div class="word" v-for="(word, ii) in line.words" :key="`${i}__${ii}`">
                {{word}} &nbsp;
              </div>
            </transition-group>
          </div>
        </transition>
      </div>
    
  </div>
</template>
<script>
import utils from '@assets/js/utils.js';
import layout from '@assets/js/layout.js';
export default {
  mixins: [utils, layout],
  props: {
    text: String,
    rows: Number,
  },
  data() {
    return {
      words: [],
      lines: [],
      prefs: {
        tick: .10, // seconds
        delay: .004, // character ** 2 delay 
        rows: 3, // max rows before deletion
        punc: {
          '.': .2, // usually an abbreviation (not the end of the sentence (see _end))
          '%': .2,
          '"': .01,
          '”': .01,
          "'": .01,
          '’': .01,
          _default: .4,
          _end: .75,
        },
        lineheight: 0,
      },
      next: {
        tick: 0,
        line: 0,
      },
      timers: {
        tick: false,
      }
    };
  },
  mounted() {
    this.words = this.text.split(/\s+/);
    this.line = 0;

    this.newLine();
    window.setTimeout(this.tick, 0);
  },
  computed: {
    getMaxRows() {
      return this.rows ? this.rows : this.prefs.rows;
    },
  },
  methods: {
    tick() {
      if (this.timers.tick) {
        window.clearTimeout(this.timers.tick);
      }
      this.timers.tick = window.setTimeout(this.newWord, this.next.tick * 1000);
    },

    newWord() {
      let word = this.words.shift();
      let mx;
      
      if (!word) {
        window.setTimeout(this.finished, this.prefs.punc._end * 1000);
        return;
      }

      this.lines[this.line].words.push(word);

      // modify the next tick..
      this.next.tick = this.prefs.tick;
      mx = word.match(/(\W)$/) || {};
      
      if (mx[0]) {
        if (this.prefs.punc[mx[0]]) {
          this.next.tick += this.prefs.punc[mx[0]];
        }
        this.next.tick += this.prefs.punc['_default'];
      }

      // slow for longer words...
      this.next.tick += (this.prefs.delay * (word.length ** 2));

      // render #1
      this.$forceUpdate();
      this.$nextTick(this.newWordOrNewLine);
    },

    newLine(sFirstWord) {
      this.line = this.next.line;
      this.lines[this.line] = {
        words: sFirstWord ? [sFirstWord] : [],
        visible: true,
      };
      // next line will be +1
      this.next.line ++;
    },

    newWordOrNewLine() {

      // check line-height
      let ele = this.$el.querySelector(`[data-line="${this.line}"]`);
      let parentRect = this.$el.getClientRects();

      if (ele) {
        let rect = ele.getClientRects();

        // console.log('Rect: ', rect);
        // console.log('Parent: ', parentRect);

        // test for overflow... 
        if (rect[0].width > parentRect[0].width) {

          // remove the last word..  and put it back on the front of the stack
          let lastWord = this.lines[this.line].words.pop();
          this.words.unshift( lastWord );

          // start a new line
          this.newLine();
          this.$forceUpdate();
          // re-tick
          this.newWord();
        } else {
          this.tick();
        }
      }
    },

    finished() {
      // console.log('Subtitles: End');
      this.$emit('ends');
    },
  },
}
</script>
<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';
  .subtitles {
    color: white;
    transition: all .3s;

    .line {

      display: inline-block;
      box-sizing: border-box;
      
      white-space: nowrap;

      .word {
        display: inline-block;
        backdrop-filter: blur(4px);
        background: rgba($black, 0.8);
        padding: .25em 0em .25em .25em;
        // margin-top: -15px;
        // padding-right: .5em;
      }
    }
  }
  .fade-enter-active, .fade-leave-active  {
    transition: opacity .6s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>