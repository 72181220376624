import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  data: function() {
    return {
      raw: {},
    };
  },
  computed: {
    model() {
      return this.$dataEngine.storyGet();
    },
    clusters() {
      return this.$dataEngine.clustersGet();
    },
    articles() {
      return this.model?.articles;
    },
    /**
     *  Basic data..
     */
    image() {
      return this.model.image;
    },
    images() {
      return this.articles?.map(a => a.image);
    },
    photos() {
      return this.articles?.filter(a => a.photo)?.map(a => a.image);
    },
    headline() {
      return this.model.name;
    },
    synopsis() {
      return this.model.synopsis;
    },
    createdAt() {
      return this.model.created_at;
    },
    lastPublishedAt() {
      return this.model.last_published_at;
    },
    topics() {
      return this.model.topics?.filter(a => a.meta.image)?.slice(0,3);
    },
    


    /**
     *  DEFUNCT?? 
     */

    getCurrentStoryIndex() {
      return this.raw?.data?.sections?.findIndex(a => a.slug == this.slug);
    },
    getCurrentStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex];
    },
    getNextStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex + 1] || {};
    },
    getPrevStory() {
      return this.raw?.data?.sections?.[this.getCurrentStoryIndex - 1] || {};
    },









    /**
     *  Component display utilities\
     */
    getViewBindingClass() {
      return `view-bind-${this.getViewBinding}`;
    },
    getViewBinding() {
      if (this.levelIsRoot) { return 'root' }
      if (this.levelIsPage) { return 'page' }
      if (this.levelIsDetail) { return 'page'; /*'detail'*/ }
      return 'error-xx';
    },
  },
  methods: {

    timeAgo(date) {
      return dayjs().to(dayjs(date));
    },

    clusterReadState(i) {
      return this.$userEngine.readState(this.clusterArticle(i).slug);
    },
    clusterIsExpanded(i) {
      // DEPRECATED
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterIsFocused(i){
      // AS ABOVE - but betterly namedified.
      return this.$dataEngine.clusterIsExpanded(i);
    },
    clusterWhen(i) {
      return this.timeAgo(this.$dataEngine.clusterDate(i));
    },
    clusterDate(i) {
      return this.$dataEngine.clusterDate(i);
    },
    clusterUpdateStatement(i) {
      return this.$dataEngine.clusterUpdateStatement(i);
    },
    clusterHeadline(i) {
      return this.$dataEngine.clusterHeadline(i);
    },
    clusterSynopsis(i) {
      return this.$dataEngine.clusterSynopsis(i);
    },
    clusterPublishers(i) {
      return this.$dataEngine.clusterPublishers(i);
    },
    clusterSlug(i) {
      return this.$dataEngine.clusterSlug(i);
    },
    clusterArticle(i) {
      return this.$dataEngine.clusterArticle(i);
    },
    clusterTopics(i) {
      return this.$dataEngine.clusterTopics(i);
    },
    clusterStateClass(i) {
      return this.clusterIsExpanded(i) ? 'state-expanded' : 'state-inactive'; // state-active
    },

    getArticle(slug) {
      // console.log(slug);
      // console.log(this.model?.articles.map(o => o.slug));
      return this.model?.articles?.find(o => o.slug == slug) || {};
    },
    getNodePath(node, slug) {
      slug = slug || this.$route.params.slug;
      // TRANSITION: while v3 & v5 live toghether, /story isn't a given..
      let sBase = this.$route.fullPath.replace(/^\//,'').split('/')[0];
      // return `/v3/story/${slug}/${node}`;


      // DON'T LOSE THE QUERY STRING!
      let sQuery = Object.keys(this.$route.query).length ? '?' + new URLSearchParams(this.$route.query).toString() : '';

      // and return..
      return `/${sBase}/${slug}/${node}${sQuery}` ;
    },


    isCurrent(slug) {
      // return slug == this.getCurrentStory?.slug;
      return this.getCurrentStory?.slug == slug;
    },
    isNext(slug) {
      return slug == this.getNextStory?.slug;
    },
    isPrev(slug) {
      return slug == this.getPrevStory?.slug;
    },






    /**
     *  Utilities - From refactor of Nav > Group > Story
     *  ------------------------------------------------ 
     */

    getReadState(slug) {
      return this.$store.getters.readState(slug);
    },

    getStory(storySlug) {
      return this.$store.getters.getLibraryItem('story_stems_v3', storySlug) || {
        _error: `Failed to load: ${storySlug}`,
      };
    },

    getTopic(topicSlug) {
      return this.$store.getters.getLibraryItem('topics_v3', topicSlug) || {
        meta: {},
      };
    },

    getTopicGroup(topicGroupSlug) {
      // console.log('topicGroupSlug: ', topicGroupSlug);
      return topicGroupSlug?.split(':').map(s => this.getTopic(s));
    },
  }
}