import { render, staticRenderFns } from "./SVG.vue?vue&type=template&id=4221b61d&scoped=true&"
import script from "./SVG.vue?vue&type=script&lang=js&"
export * from "./SVG.vue?vue&type=script&lang=js&"
import style0 from "./SVG.vue?vue&type=style&index=0&id=4221b61d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4221b61d",
  null
  
)

export default component.exports