<template>
  <transition name="wipe-up">
    <div class="message-background" v-if="isVisible" :class="[{ 'has-app' : hasApp }, `message-type-${getType}`]">
      <div class="message-wrapper">
        <div class="brand">
          <!-- <img :src="getLogo" /> -->
          <global-svg type="logo" width="62px" height="18px" color="#fff"></global-svg>
        </div>

        <div class="stack">
          <div class="intro">
            <div class="image">
              <img src alt />
            </div>
            <div class="copy">
              <h2>{{getHeading}}</h2>
              <div v-html="getCopy"></div>
            </div>
          </div>

          <div class="report-dial" v-if="getReport && getReport.showDial">
            <dial size="fill" class="dial-fill"/>
          </div>

          <div class="report-stats" v-if="getReport && getReport.showStats">
            <div class="inner-stat outer-ring">
              <span class="detail">Sources & Opinions</span>
              <span class="stat">{{ getPublishersStat }}</span>
              <span class="variance">{{ getPublishersPosition }}%</span>
            </div>

            <div class="inner-stat inner-ring">
              <span class="detail">Reading Time</span>
              <span class="stat">{{ getReadingTimeStat }}m</span>
              <span class="variance">{{ getReadingTimePosition }}%</span>
            </div>

            <div class="inner-stat mid-ring">
              <span class="detail">Countries & Topics</span>
              <span class="stat">{{ getTopicsStat }}</span>
              <span class="variance">{{ getTopicsPosition }}%</span>
            </div>
          </div>

          <div class="report-message" v-if="getReport && getReport.showMessage">
            <p>{{ getReport.message }}</p>
          </div>

          <div class="actions-list">
            <div class="social-list" v-if="showSocial">
              <div class="social-btn" v-on:click="handleSocial('twitter')"><global-svg type="twitter" color="#FFF" width="15px" height="18px"/></div>
              <div class="social-btn" v-on:click="handleSocial('facebook')"><global-svg type="facebook" color="#FFF" width="8px" height="18px"/></div>
              <div class="social-btn" v-on:click="handleSocial('linkedin')"><global-svg type="linkedin" color="#FFF" width="15px" height="18px"/></div>
            </div>

            <div v-for="action in getActions" :key="action.code" class="action">
              <!-- Product -->
              <StripeCheckout v-if="action.type == 'product'" :period="action.period" mode="message-card" class="product"/>
              <!-- <div class="product">
                <div class="product-intro">
                  <span class="product-heading">{{action.product.heading}}</span>
                  <span class="product-description">{{action.product.description}}</span>
                </div>
                <div class="product-price">
                  <span class="locale-price">{{action.product.locale}}{{action.product.price}}</span>
                </div>
              </div> -->
              <!-- Cancel -->
              <div v-else-if="action.type == 'cancel'" class="cancel-action">
                <button v-on:click="handleAction(action.code, action)" class="ghost-btn" >{{action.text}}</button>
              </div>
              <!-- Standard Button -->
              <div v-else class="default-action">
                <button v-on:click="handleAction(action.code, action)" class="is-button no-arrow" v-bind:class="'type-' + action.type">{{action.text}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import StripeCheckout from 'app/components/Pro/Stripe.vue';
export default {
  components: {
    StripeCheckout,
  },
  data() {
    return {
      visible: false,
      code: false,
      callback: false,
      view: {
        HASH_report_today: {
          type: "report",
          heading: "Today's news diet",
          copy:
            "OneSub tracks the news you read to give you the best possible coverage for the day.",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "cancel",
              type: "cancel",
              text: "Close"
            }
          ],
          report: {
            showDial: true,
            showStats: true,
            showMessage: false,
            message: ''
          }
        }, // End HASH_daily_report
        HASH_report_mock: {
          type: "report",
          heading: "Today's news diet",
          copy:
            "OneSub tracks the news you read to give you the best possible coverage for the day.",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "sign-up",
              type: "cta",
              text: "Get a Free Account"
            },
            {
              code: "close",
              type: "cancel",
              text: "I'll sign up later",
            },
          ],
          report: {
            showDial: true,
            showStats: true,
            showMessage: false,
            message: ''
          }
        }, // End HASH_daily_report
        HASH_go_pro: {
          type: "pro",
          heading: "Become a OneSub Pro",
          copy:
            "OneSub exists to help journalism break free of attention-economics. <br/><br/>80% of your subscription is set aside for distribution to the publishers you read.",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "1-week",
              type: "product",
              period: "W",
            },
            {
              code: "1-month",
              type: "product",
              period: "M",
            },
            {
              code: "1-year",
              type: "product",
              period: "Y",
            },
            {
              code: "link",
              type: "cancel",
              text: "Not right now",
              link: () => `#pro_nopro`,
            }
          ]
        }, // End PRO001

        HASH_pro_thanks: {
          type: "pro",
          heading: "Thank you! 🤩",
          copy: "<p>You're a superstar.<br/><br/>Thank you for choosing to support us.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "close",
              type: "cta",
              text: "I'm awesome.",
            },
          ]
        },

        HASH_pro_nopro: {
          type: "pro",
          heading: "Okay, no sweat. 😅",
          copy: "<p>Could we ask a favour?<br/><br/>Could you let us understand why<br/>you chose not to Go Pro today?</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Sure, I can help.",
              link: () => `https://onesub.typeform.com/nopro`,
            },
            {
              code: "close",
              type: "cancel",
              text: "No, sorry.",
            },
          ]
        },


        

        HASH_go_pro_nextup: {
          type: "pro",
          heading: "Time is precious.",
          copy: `
            <p>Get the most important story next with OneSub Pro.</p>
            <p>Spend your reading-time wisely.</p>
            <p>Know what matters. Fast.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "1-month",
              type: "product",
              period: "M",
            },
            {
              code: "1-year",
              type: "product",
              period: "Y",
            },
            {
              code: "cancel",
              type: "cancel",
              text: "Maybe later",
            },
          ]
        },
        HASH_coming_soon_most_interesting: {
          type: "pro",
          heading: "Time is precious.",
          copy: `
            <p><strong>(Coming Soon to OneSub Pro)</strong></p>
            <p>OneSub will use what it knows about what you've read to give you the best story to read next.</p>
            <p>Less '<em>oh, more of the same</em>'.<br/>More '<em>ooh, that is interesting</em>'.</p>
            <p>Know what matters. Fast.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "close",
              type: "cta",
              text: "Awesome, looking forward to it!",
            },
          ]
        },




























        HASH_thread_handles: {
          type: "social",
          heading: "Change your ~handle",
          copy: "<p>To protect the OneSub Threads from handle-squatting, we're planning to maintain parity with Twitter for a while.<br/><br/>We're working to automate this.<br/><br/>For now, use this preformatted Twitter DM and we'll update your OneSub handle to match your Twitter handle.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          social: {
            // Jim -  Used this as an example. Additional social object handles visibility, shareText and shareLink
            // If this is what you wanted, we can add it to the other objects where required. Social only shows when visible=true
            visible: false,
            shareText: '',
            shareLink: '',
          },
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Send a Twitter DM",
              link: () => `https://twitter.com/messages/compose?recipient_id=597381458&text=Please change the OneSub handle ~${this.$store.getters.screenName} to match this Twitter account.`,
            },
            {
              code: "close",
              type: "cancel",
              text: "Maybe later",
            },
          ]
        },
        HASH_threads_welcome: {
          type: "social",
          heading: "Welcome to Threads 💙",
          copy: "<p>Welcome to our new microblogging platform for thoughtful people.<br/><br/>You have managed to get <strong>very early access</strong> to the platform.<br/><br/>Please take a moment to see how it works and to understand the environment we're trying to create.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "What are Threads?",
              link: `#threads_welcome_what`,
            },
          ]
        },
        CHX_threads_invited: {
          type: "social",
          heading: "Announcing: Threads 💙",
          copy: `
            <p>OneSub is launching a microblogging platform for thoughtful people who share an interest in the news.</p>
            <p>Take clippings of the news and thread them together into complete thoughts.</p>
            <p>NB. We have a waitlist for Threads access because there's already been<br/>a lot of demand.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Tell me more / Join the waitlist",
              link: `/threads`,
            },
            {
              code: "close",
              type: "cancel",
              text: "Maybe later",
            },
          ]
        },
        CHX_welcome_to_threads: {
          type: "social",
          heading: "Welcome to Threads! 💙",
          copy: `
            <p>Hooray - your account is now active.</p>
            <p>Get ready to<br/><strong>Clip, Thread & Share</strong>.</p>
            <p>Before you get started, please take a moment to set up your profile page.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Set up my Profile",
              link: '/account/threads'
            },
            {
              code: "close",
              type: "cancel",
              text: "Maybe later",
            },
          ]
        },
        CHX_threads_activated: {
          type: "social",
          heading: "You're on the list..",
          copy: `
            <p>Hooray - Your on the Waitlist.</p>
            <p>If you're really keen to get started why not get our attention on Twitter and we'll see if we can bump you up the list a bit. 😉</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Do a little Tweet",
              link: `https://twitter.com/share?text=Yay! I'm on the waitlist for OneSub Threads.&url=https://onesub.io/threads/clip-thread-share&hashtags=KnowWhatMatters`,
            },
            {
              code: "close",
              type: "cancel",
              text: "Maybe later",
            },
          ]
        },
        CHX_welcome_to_threads_XX: {
          type: "social",
          heading: "Clip. Thread. Share.",
          copy: `
            <p>Hooray - you're all set! 🥳</p>
            <p>The best way to get started is simply to get into the habit of taking clippings of articles that interest you.</p>
            <p>The Threads will take shape naturally as you collect your thoughts.</p>
            <p>-</p>
            <p>Now you're in... <br/><br/>..help us with a bit of bragging? 🤷‍♂️</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "I can brag a little! 😁",
              link: `https://twitter.com/share?text=Yay! I've just set up my OneSub Threads account.&url=${document.location.origin}/~${this.$store.getters.screenName}&hashtags=KnowWhatMatters`,
            },
            {
              code: "close",
              type: "cancel",
              text: "Promise I will later...",
            },
          ]
        },
        CHX_create_clip: {
          type: "social",
          heading: "Ready for your first Clip?",
          copy: `
            <p>Threads are made by threading together clippings from the web.</p>
            <p>Find an article from a newspaper or common blog platform to get started.</p>
            <ul>
              <li><strong>On the web</strong><br/><span class="small">Hit "<strong>+Clip</strong>" (top right) and paste your link.</span><br/><br/><li>
              <li><strong>On mobile</strong> (beta)<br/><span class="small">Hit "<strong>Share</strong>" from any app &amp; pick OneSub.</span><br/><br/><li>
              <li><strong>In Chrome</strong><br/><span class="small">Coming soon to our Chrome Extension.</span><br/><br/><li>
            </ul>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Great, I'll give it a go!",
              link: `#threads_welcome_share`,
            },
            {
              code: "mobile-beta",
              type: "cancel",
              text: "Get the iPhone / Android App",
            },
          ]
        },
        HASH_threads_welcome_share: {
          type: "social",
          heading: "Sharing threads..",
          copy: `
            <p><strong>Please share what you create!</strong></p>
            <p>Threads of 2+ articles can published.</p>
            <p>When you publish, please share on your favorite social media channels.</p>
            <p>You can keep adding to live threads.</p>
            <p>We're adding support to automatically share to Twitter - let us know if you'd like to test Twitter support.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "I'm ready to get started..",
              link: `#threads_welcome_legal`,
            },
          ]
        },
        HASH_threads_welcome_legal: {
          type: "social",
          heading: "One last thing..",
          copy: `
            <p><strong>An important word about T&Cs</strong></p>
            <p>OneSub Threads are still experimental so it's important that you understand you're taking part in our experiment.</p>
            <p>Until we define our terms, assume they're like Twitter's - you're granting us an unlimited license to use the words & ideas you contribute on OneSub.</p>
            <p>We can't offer any guarantees that things won't break, get lost, disappoint you or explode in some way.</p>
          `,
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "close",
              type: "cta",
              text: "I understand, that's fine.",
            },
          ]
        },

        // Callbacked ... 
        CBX_threads_publish: {
          type: "social",
          heading: "Ready to publish?",
          copy: "<p>You can keep adding Clips to your Thread once it's published... </p>"
              + "<p>...but you can't go back and edit what you've already said.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "publish",
              type: "cta",
              text: "Yay! Publish.",
            },
            {
              code: "cancel",
              type: "cancel",
              text: "Okay, not yet"
            }
          ]
        }, // End HASH_chrome_require_auth


        BRX001: {
          type: "brx",
          heading: "Opening Links",
          copy: "<p>Reading news within OneSub helps you to understand your news-diet.</p>"
              // + "<p>We're also working to protect you from trackers and annoying consent popups within the app.</p>"
              + "<p>Where you would like to open links?</p>",
          actions: [
            {
              code: "onesub",
              type: "cta",
              text: "Open links in OneSub"
            },
            {
              code: "safari",
              type: "cancel",
              text: "Use Safari for now"
            },
          ]
        }, // End PRO001
        SIU001: {
          type: "brx",
          heading: "OneSub is here to help you",
          copy: "<p>Reading news within OneSub helps you to understand your news-diet.</p>"
              + "<p>We never sell your data. You can delete your account at any time.</p>"
              + "<p>Sign up for a free account.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "sign-up",
              type: "cta",
              text: "Get started"
            },
            {
              code: "close",
              type: "cancel",
              text: "Not now"
            }
          ]
        }, // End SIU001
        TH001: {
          type: "destructive",
          heading: "Are you sure?",
          copy: "<p>This action cannot be undone.</p>"
              + "<p>Do you really wish to delete this clip?</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "delete",
              type: "cta",
              text: "Yep. Delete."
            },
            {
              code: "close",
              type: "cancel",
              text: "Okay, maybe not."
            }
          ]
        }, // End TH001
        TH002: {
          type: "destructive",
          heading: "Are you sure?",
          copy: "<p>This action cannot be undone.</p>"
              + "<p>Are you sure you wish to delete all of your hard work?</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "delete",
              type: "cta",
              text: "Yep. Delete."
            },
            {
              code: "close",
              type: "cancel",
              text: "Okay, maybe not."
            }
          ]
        }, // End TH002
        HASH_chrome_require_auth: {
          type: "chrome",
          heading: "Please sign in.",
          copy: "<p>Our Chrome Extension needs your permission to operate properly.</p>"
              + "<p>Please sign in, or create a free account.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "sign-in",
              type: "cta",
              text: "Sign in to OneSub",
            },
            {
              code: "sign-up",
              type: "cancel",
              text: "Create a free account"
            }
          ]
        }, // End HASH_chrome_require_auth
        HASH_chrome_completed_auth: {
          type: "chrome",
          heading: "Hurrah. We're all done.",
          copy: "<p>You can now close this tab.</p>"
              + "<p>You will find alternative points of view in the bottom right hand corner of most popular news websites.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "tab-close",
              type: "cta",
              text: "Close this tab",
            },
            {
              code: "route-account",
              type: "cancel",
              text: "Go to my account settings"
            }
          ]
        }, // End HASH_chrome_require_auth
        HASH_intro_deck: {
          type: "social",
          heading: "Welcome to OneSub Deck.",
          copy: "<p>This is a very experimental new way to compile & manage your Threads.</p>"
              + "<p>If you're not signed up to Threads it might not make much sense.</p>",
          logo: "./assets/images/onesub-brand-pro.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Tell me more / Join the waitlist",
              link: `/threads`,
            },
            {
              code: "close",
              type: "cancel",
              text: "I'm in already / Maybe later",
            },
          ]
        }, // End HASH_chrome_require_auth




















        HASH_v3_intro: {
          type: "brand",
          heading: "Know what matters. Fast.",
          copy: "<p>OneSub is a better way to read the news.</p>"
              + "<p>OneSub helps you to stay fully informed about what is happening across the world in the time you have available.</p>",
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "sign-up",
              type: "cta",
              text: "Get a Free Account"
            },
            {
              code: "close",
              type: "cancel",
              text: "I'll sign up later",
            },
          ]
        }, // End HASH_onesub_new_intro
        CHX_v3_welcome__XX_REMOVED_WHILE_THREADS_IS_RELAUCHING__NOV_2022___Cos_they_clash: {
          type: "brand",
          heading: "OneSub - Better News.",
          copy: "<p>OneSub's had another little update! 🚀</p>"
              + "<p>You will find our website & apps much easier to use and much more informative.</p>"
              + "<p>Would you like to know what we've improved?</p>",
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Oooh, yes, what's new?",
              link: `/#v3_welcome_format`,
            },
            // {
            //   code: "close",
            //   type: "cancel",
            //   text: "I'll take a look later.",
            // },
          ]
        }, // End HASH_v3_welcome
        HASH_v3_welcome_format: {
          type: "brand",
          heading: "Know What Matters. Fast.",
          copy: "<p>Get to the stories that matter, fast.</p>"
              + "<p>Our new look gives you<br/>super-quick access to news<br/>at home and abroad.</p>"
              + "<p>We give you the gist of every story<br/>from multiple sources.</p>"
              + "<p>Our AI personalises your coverage,<br/>so see all sides.</p>",
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            // {
            //   code: "link",
            //   type: "cta",
            //   text: "Can I see my progress?",
            //   link: `/#v3_welcome_dial`,
            // },
            {
              code: "link",
              type: "cta",
              text: "Awesome. What about privacy?",
              // spit the journey - we don't want to ask Pros for cash twice! :o)
              link: () => this.isPro ? `/#v3_welcome_privacy_pro` : `/#v3_welcome_privacy`,
            },
          ],
        }, // End HASH_v3_welcome_format
        HASH_v3_welcome_dial: {
          type: "report",
          heading: "Know your daily news diet.",
          copy: "<p>We're here to help you understand<br/>your diet of daily news.</p>"
              + "<p>Complete all three rings each day to ensure your reading a balanced diet of sources & subjects.</p>",
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "link",
              type: "cta",
              text: "Awesome. What about privacy?",
              // spit the journey - we don't want to ask Pros for cash twice! :o)
              link: () => this.isPro ? `/#v3_welcome_privacy_pro` : `/#v3_welcome_privacy`,
            },
          ],
          report: {
            showDial: true,
            showStats: false,
            showMessage: false,
            message: ''
          }
        }, // End HASH_v3_welcome
        HASH_v3_welcome_privacy: {
          type: "pro",
          heading: "We don't sell people.",
          copy: "<p>🚫 No ads. No trackers. No creepy. 🚫</p>"
              + "<p>We do not sell your data.<br/>We never will.</p>"
              + `<p>To keep OneSub's robots fed, we do offer a "Pro" account which gives you full story histories, greater flexibility & lots more.</p>`
              + `<p>Please consider trying a "Pro" account.<br/>Help us fix online news.</p>`,
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "1-week",
              type: "product",
              period: "W",
            },
            {
              code: "1-month",
              type: "product",
              period: "M",
            },
            // {
            //   code: "1-year",
            //   type: "product",
            //   period: "Y",
            // },
            {
              code: "cancel",
              type: "cancel",
              text: "I will do, later."
            }
          ],
        },
        HASH_v3_welcome_privacy_pro: {
          type: "pro",
          heading: "We don't sell people.",
          copy: "<p>🚫 No ads. No trackers. No creepy. 🚫</p>"
              + "<p>We do not sell your data and we never will.</p>"
              + `<p>Your "Pro" subscription is helping us<br/>fix the way news is funded<br/>without farming people for their data.</p>`
              + `<p>Thank you for being amazing!</p>`,
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "cancel",
              type: "cancel",
              text: "Awesome, let's dive in."
            }
          ],
        }, // End HASH_v3_welcome_privacy










        HASH_v3_inactive_edition: {
          type: "plain",
          heading: () => {
            let sLocale = this.$dataEngine.editionMeta?.locale?.name || 'there';
            return `Hello ${sLocale} 👋`;
          },
          copy: () => {
                let sLocale = this.$dataEngine.editionMeta?.locale?.name || 'country';
                return ` 
                  <p>OneSub is a small organisation and we currently publish editions in the UK and US.</p>
                  <p>We hope to publish a<br/>${sLocale} Edition very soon</p>
                  <p>In the meantime, please enjoy our International Edition.</p>
                `;},
          logo: "./assets/images/onesub-brand.svg",
          actions: [
            {
              code: "vote",
              type: "cta",
              text: () => {
                // let sLocale = this.$dataEngine.editionMeta?.locale?.name || 'my country';
                return `Show me the International Edition`;
              },
            },
          ]
        }, // End HASH_onesub_new_intro
      }
    };
  },

  mounted() {
    this.EventBus.$on("showInboundMessage", event => {
      this.code = event.code;
      this.callback = event.callback;
      this.visible = true;
    });
    // on mount -- 
    this.checkHash();
    // on hash-change -- 
    window.addEventListener("hashchange", this.checkHash, false);
    // on auth/de-auth -- 
    this.EventBus.$on('api:account',() => {
      window.setTimeout(this.checkHash, 1000);
      window.setTimeout(this.checkHash, 2000);
      window.setTimeout(this.checkHash, 3000);
    });
  },

  computed: {
    hasApp() {
      return this.$store.getters.hasApp;
    },

    isVisible() {
      return this.visible;
    },

    userScreenName() {
      return this.$store.getters.screenName;
    },

    // Content
    getLogo() {
      switch (this.view[this.code].type) {
        case "pro":
          return "./assets/images/onesub-brand-pro.svg";
      }
      return "./assets/images/onesub-brand.svg";
    },

    getType() {
      return this.view[this.code].type;
    },

    getHeading() {
      let r = this.view[this.code].heading;
      return ( typeof r === 'function' ) ? r() : r;
    },

    getCopy() {
      let r = this.view[this.code].copy;
      return ( typeof r === 'function' ) ? r() : r;
    },

    showSocial() {
      return this.view[this.code].social?.visible;
    },

    getReport() {
      return this.view[this.code].report || {};
    },

    // Actions
    getActions() {
      return this.view[this.code].actions.map(a => {
        a['text'] = ( typeof a['text'] === 'function' ) ? a['text']() : a['text'];
        return a;
      });
    },

    hasChallengeMessages() {
      let bands = this.$store.getters.getMicroChallenges;
      let messages = [];
      // console.log('Bands: ', bands);
      if (!bands.length){
        return [];
      }
      // build a list of all the current challenges that have matching messages!
      bands.forEach((band) => {
        // console.log('Band: ', band);
        band.challenges.forEach((challenge) => {
          // console.log('Challenge: ', challenge);
          let sSlug = 'CHX_' + challenge.code.replace(/-/g,'_');
          // console.log('Slug: ', sSlug);
          if (this.view[sSlug]){
            // console.log('Message: ', sSlug);
            messages.push(sSlug);
          }
        })
      });

      console.log('Messages: ', messages);
      return messages;
    },

    // Daily Report
    reportData() {
      return this.$store.getters.getLibraryItem('dial');
    },

    // Stats
    getPublishersStat() {
      return this.reportData?.rings?.sources?.cx || 0;
    },

    getTopicsStat() {
      return this.reportData?.rings?.topics?.cx || 0;
    },

    getReadingTimeStat() {
      const timeInSeconds = this.reportData?.rings?.time?.cx || 0
      return Math.ceil(timeInSeconds / 60);
    },

    // Percentage Complete
    getPublishersPosition() {
      return Math.ceil(this.reportData?.rings?.sources?.px || 0);
    },

    getTopicsPosition() {
      return Math.ceil(this.reportData?.rings?.topics?.px || 0);
    },

    getReadingTimePosition() {
      return Math.ceil(this.reportData?.rings?.time?.px || 0);
    },

    getOverallProgress() {
      const totals = this.getPublishersPosition + this.getTopicsPosition + this.getReadingTimePosition;
      return Math.ceil(totals / 3);
    }
  },

  watch: {
    hasChallengeMessages: function (to, from) {

      // grab the local log of things we've shown..
      let aLog = JSON.parse(localStorage.getItem('chx')) || [];

      // on every change, show the top-most valid challenge ONCE per user, per device
      let oldFirst = from.shift();
      let newFirst = to.find(s => {
        // ignore if we've shown it here, recently..
        // console.log(`Display or ignore ${s}? Display: `, (aLog.includes(s) ? 'no, skip' : 'yes'));
        return aLog.includes(s) ? false : true;
      });

      if (newFirst && (newFirst != oldFirst)) {
        // console.log('New message to Display!', newFirst);

        // otherwise, log this (and the previous five things..) ...new Set() is for uniqueness
        localStorage.setItem('chx', JSON.stringify([...new Set([newFirst,...aLog].slice(0,6))]));

        // and show it.. 
        this.EventBus.$emit('showInboundMessage', {
          code: newFirst,
          callback: this.buttonCallback,
        });
      }
    }
  },

  methods: {
    handleAction(code, args) {
      if (this.callback) {
        this.callback(code, args);
      }

      this.visible = false;
    },

    handleSocial() {
      // Jim - Payload is set, just needs to be passed to an event to handle opening new window etc..
      // const payload = {
      //   type: type,
      //   text: this.view[this.code].social.shareText,
      //   link: this.view[this.code].social.shareLink
      // }

      // console.log(payload);
    },

    checkHash() {
      // grab for the hash
      let h = document.location.hash.substring(1);
      // see if it's useful

      // console.log("HashChange: ", h);
      if (h){
        // normally we're looking for a hash-key..
        let bValid = this.view['HASH_' + h];
        
        // .. but for tesing challenges.. (could/should lock this to alphas)
        let bTestCHX = (h.search(/^CHX_/) == 0) && this.view[h];

        if (bValid || bTestCHX){
          // unset it from the URL (keep it during local dev)
          // if (process.env.NODE_ENV != 'development') {
            history.replaceState("", document.title, window.location.pathname + window.location.search);
          // }
          // and do our business..
          this.EventBus.$emit('showInboundMessage', {
            code: bTestCHX ? h : 'HASH_' + h,
            callback: this.buttonCallback,
          });
        }
      }
    },
    buttonCallback(r, args) {
      // run late interpolation of the link (everything?)
      if ( typeof args.link === 'function' ){
        args.link = args.link();
      }
      
      // is this an external link?
      let bExt = args.link?.match(/^http/) != null;
      let bHash = args.link?.match(/\/?#/) != null;

      // console.log(args.link, bExt, bHash);

      switch(r){
        case "sign-in":
          this.EventBus.$emit('showLoginModal',{ mode: 'sign-in', redirect: '/account#chrome_completed_auth', });
          break;
        case "sign-up":
          this.EventBus.$emit('showLoginModal',{ mode: 'sign-up', redirect: '/account#chrome_completed_auth', });
          break;
        case "tab-close":
          this.EventBus.$emit("appCallback", {
            type: 'close_options',
            data: {},
          });
          break;
        case 'profile':
          this.$router.push(`/~${this.$store.getters.screenName}`);
          break;
        case "route-account":
          this.$router.push('/account');
          break;
        case "link":
          // route if we can (smoother) ... or navigate
          if ( !bExt && !bHash && this.$router.resolve(args.link)?.resolved?.matched?.length ) {
             this.$router.push(args.link);
          } else {
             top.document.location.href = args.link;
          }
          break;
        case 'mobile-beta': 
          top.document.location.href = 'https://qrco.de/bctT8G';
          break;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/_variables";
@import 'src/assets/scss/__2020_v3.scss';

.wipe-up-enter-active {
  transition: all .3s ease;
}
.wipe-up-leave-active{
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.wipe-up-enter, .wipe-up-leave-to {
  // transform: translateY(100vh);
  opacity: 0;
}

.message-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3147483000; // intercom: 2147483001 !?
  background: $background-faded;
  backdrop-filter: blur(3px);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-wrapper {
  position: fixed;
  left: 2%;
  right: 0;
  bottom: 0;
  width: 96%;
  height: 96%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  z-index: 3147483001; // intercom: 2147483001 !?
  border-top-left-radius: $large-border-radius;
  border-top-right-radius: $large-border-radius;
  @include black-shadow;

  background: $base-primary;
  .message-type-pro & {
    background: $green-tone-7;
  }

  .message-type-social & {
    background: $blue-tone-7;
  }

  .message-type-destructive & {
    background: $red-tone-7;
  }

  .message-type-report & {
    background: $red-tone-7;
  }

  .message-type-brand & {
    background: $yellow-tone-7;
  }

  // In app - can't expand out of the webView, so fill it right up.
  .has-app & {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    .brand {
      // Already shown above
      display: none;
    }
  }

  @media (min-width: 600px) {
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    width: 350px;
    height: auto;
    // max-height: 600px;
    border-radius: $border-radius;
    padding: 40px 30px;
    @include black-shadow;
  }

  .brand {
    display: flex;
    justify-content: center;
  }

  .stack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .intro {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      flex-grow: 1;

      .copy {
        
        h2,
        p,
        div {
          color: $white-primary;
        }

        h2 {
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 10px;
          margin-top: 0;
        }

        p,
        div {
          // font-size: 16px;
          line-height: 1.4; 
        }

        @media (min-width: 600px) {
          padding-bottom: 30px;
        }

        // v-html classes are not scoped... 
        ::v-deep .small {
          font-size: 12px;
        }
      }
    }

    .social-list {
      display: flex;
      justify-content: space-around;
      margin-bottom: 25px;

      .social-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border: 1px solid $blue-tone-3;
        border-radius: 50%;
        cursor: pointer;
        @include ease_animation;

        &:hover {
          background: rgba($blue-tone-3, 0.1);
        }
      }
    }

    .actions-list {
    color: red;
      .action {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .default-action {
        button {
          width: 100%;
          padding: 16px;
          background: $white-secondary;
          font-size: 14px;

          &.type-cta, 
          &.type-primary {
            background: $highlight-primary;
  
            .message-type-pro & {
              background: $green-tone-3;
              color: $white-primary;
            }

            .message-type-social & {
              background: $blue-tone-3;
              color: $white-primary;
            }

            .message-type-destructive & {
              background: $red-tone-3;
              color: $white-primary;
            }
          }
        }
      }


      .cancel-action {
        display: flex;
        justify-content: center;
        margin: 10px 0 0 0;

        .ghost-btn {
          color: $white-primary;
          font-size: 12px;
          font-weight: bold;
          background: unset;
          border: unset;
          outline: unset;
          cursor: pointer;
        }
      }
    }
  }

  .report-dial {
    margin: auto;
    flex-grow: 1;
  }

  .report-stats {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5em;

      .inner-stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .detail {
          @include typo-size-small;
          padding: 0.5em;
          color: $white-primary;
          border-radius: $el-border-radius;
        }

        .stat {
          @include typo-size-headline;
          font-size: 2.5em;

          .mobile & {
            font-size: 2em;
          }
        }

        .variance {
          @include typo-size-small-heading;
          color: $white-primary;
        }

        &.outer-ring {
          // Yellow
          .detail {
            color: $yellow-tone-3;
            background: rgba($yellow-tone-3, 0.1);
          }

          .stat {
            color: $yellow-tone-3;
          }
        }

        &.mid-ring {
          // Orange
          .detail {
            color: $orange-tone-3;
            background: rgba($orange-tone-3, 0.1);
          }

          .stat {
            color: $orange-tone-3;
          }
        }

        &.inner-ring {
          // Red
          .detail {
            color: $red-tone-3;
            background: rgba($red-tone-3, 0.1);
          }

          .stat {
            color: $red-tone-3;
          }
        }

        &.progress-ring {
          // Green
          .detail {
            color: $green-tone-3;
            background: rgba($green-tone-3, 0.1);
          }

          .stat {
            color: $green-tone-3;
          }
        }
      }
    }
}
</style>