import dayjs from 'dayjs'
export default {
  computed: {

    account() {
      return this.$store.getters.getAccount;
    },

    hasApp() {
      return this.$store.getters.hasApp;
    },

    isPro () {
      return Boolean(this.$store.getters.isPro);
    },

		isAlpha () {
      return Boolean(this.$store.getters.isAlpha);
		},

		isBeta() {
			return Boolean(this.$store.getters.isBeta);
		},

    isSignedIn() {
      return this.$store.getters.isLoggedIn; 
    },
    
		isLoggedIn () {
			return this.isSignedIn;
		},

		isNotSignedIn () {
      return Boolean(!this.isSignedIn);
    },

		isNotLoggedIn () {
      return Boolean(!this.isSignedIn);
    },

    isTopicDebug() {
      return this.$store.getters.debugAllTopics;
    },
    
    // acceessibility switches
    a11yDialOff() {
      return !this.a11yDialOn;
    },
    a11yDialOn() {
      return !this.$userEngine.getPref('a11y.dial.off');
    },

  },
  methods: {
    ago: function (d) {
      return d ? dayjs(d).fromNow() : '';
    },
    when: function (d) {
      return d ? dayjs(d).fromNow() : '';
    }
  }
}