<template>
  <div>
    <transition name="slide">
      <div v-show="visible" class="auth-wrapper" :class="[`mq-${$mq}`]">
        <div class="close" @click="hideModal()">
          <base-svg svg="close" height="0.85rem"/>
        </div>
<!----------------------------------------------------------------------------------------------------------------|
  Sign Up
|----------------------------------------------------------------------------------------------------------------->
<!-------------------------------------- Sign Up: Form -------------------------------------->
        <div class="auth-inner" v-if="state == 'sign-up'" :class="[`mq-${$mq}`]">          
          <div class="intro">
            <h1><span class="highlight-yellow">Get started on OneSub</span></h1>
            <h2>Free forever</h2>

            <div class="features" v-if="features[state]">
              <div v-for="(feature, idx) in features[state]" :key="idx">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <!-- <rect class="rect" y="3" width="14" height="3"/> -->
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6209 0.225302C13.9213 0.525706 13.9213 1.01276 13.6209 1.31316L5.15931 9.7747C4.85891 10.0751 4.37186 10.0751 4.07146 9.7747L0.225302 5.92854C-0.0751008 5.62814 -0.0751008 5.14109 0.225302 4.84069C0.525706 4.54028 1.01276 4.54028 1.31316 4.84069L4.61538 8.14291L12.533 0.225302C12.8334 -0.0751008 13.3204 -0.0751008 13.6209 0.225302Z" fill="black"/>
                </svg>
                <span>{{ feature }}</span>
              </div>
            </div>
          </div>

          <form v-if="!isWorking" class="v5-form" @submit.prevent="signUp()">
            <div class="base-msg error" v-if="responseErrorMessage">
              {{ responseErrorMessage }}
            </div>
            
            <div class="field-row">
              <input type="text" id="name" v-model="form.sign_up.firstName" placeholder="Name" @keyup.space="expandNameField()">
              <input v-if="showLastName" type="text" id="last_name" v-model="form.sign_up.lastName" placeholder="Last Name">
            </div>
            <input type="email" id="email" v-model="form.sign_up.email" placeholder="Email">
            <input type="password" id="password" v-model="form.sign_up.password" placeholder="Create a password">
            <input id="terms" v-model="form.sign_up.terms" class="checkbox-custom" name="terms" type="checkbox">
            <label for="terms" class="checkbox-custom-label">
              <p>I'm onboard with the <a href="https://onesub.io/privacy" target="_blank">privacy</a> and <a href="https://onesub.io/terms" target="_blank">terms</a></p>
            </label>

            <button type="submit">Create account</button>
            <!-- <p>No password needed. We'll email you a magic link next time you need to sign in.</p> -->
            <div class="switch-state">
              <a @click="switchState('sign-in')">Already have an account? Sign in</a>
            </div>
          </form>

          <div v-else class="v5-loader"></div>
        </div>

<!-------------------------------------- Sign Up: Personalise -------------------------------------->
        <div class="auth-inner overflow-wrap" v-if="state == 'sign-up:personalise'" :class="[`mq-${$mq}`]">
          <div class="intro">
            <h1><span class="highlight-yellow">Personalise your experience</span></h1>
            <h2>Optional</h2>
            <p>Choose a few topics you are most interested in. This will help our AI start to learn about you.</p>
          </div>

          <div v-if="!isWorking" class="topic-container">
            <div class="topic-container-inner">
              <div class="topic-tag" :class="{'selected' : topic.selected}" v-for="(topic, idx) in topics" :key="idx" @click="selectTopic(topic)">
                <img v-if="topic.image" :src="topic.image"/>
                <span class="name"><span class="highlight-yellow">{{ topic.name }}</span></span>
                <span class="byline">{{ topic.byline }}</span>
              </div>
            </div>
          </div>

          <form v-if="!isWorking" class="v5-form v5-form-float" @submit.prevent="setTopicPreferences()">
            <button type="submit">Next</button>
          </form>

          <div v-else class="v5-loader"></div>
        </div>

<!-------------------------------------- Sign Up: Notifications -------------------------------------->
        <div class="auth-inner" v-if="state == 'sign-up:notifications'" :class="[`mq-${$mq}`]">
          <div class="intro">
            <h1><span class="highlight-yellow">Never miss a story</span></h1>
            <p>Get email digests direct from OneSub with a snapshot summary of what's happening.</p>
          </div>

          <form v-if="!isWorking" class="v5-form notifications-form" @submit.prevent="updateNotificationPreferences()">
            <!-- <label for="updates" class="checkbox-custom-label">
              <input id="updates" class="checkbox-custom" name="updates" type="checkbox" v-model="form.account.details.updatesEmailOn">
              <div class="check-stack-box" :class="{'checked' : form.account.details.updatesEmailOn}"></div>
              <p>Set up OneSub News Bulletin</p>
            </label> -->

            <label for="updates" class="checkbox-label">
              <input type="checkbox" id="updates" :value="true" v-model="form.account.details.updatesEmailOn">
              <p>Send me story updates</p>
            </label>


            <div v-if="form.account.details.updatesEmailOn">
              <h4 class="inner-header">Email me stories</h4>
              <div class="group group-2">
                <label :for="frequency.name" class="checkbox-custom-label" v-for="frequency in getNotifcations.frequency" :key="frequency.code">
                  <input type="radio" :id="frequency.name" class="checkbox-custom" :name="frequency.name" :value="frequency.id" v-model="form.account.details.updatesEmailFreq">
                  <p>{{ frequency.name }}</p>
                </label>
              </div>

              <h4 class="inner-header">... in the</h4>
              <div class="group group-3">
                <label :for="frequency.name" class="checkbox-custom-label" v-for="frequency in getNotifcations.timings" :key="frequency.code">
                  <input type="radio" :id="frequency.name" class="checkbox-custom" :name="frequency.name" :value="frequency.id" v-model="form.account.details.updatesEmailTiming">
                  <p>{{ frequency.name }} ({{frequency.note}})</p>
                </label>
              </div>
            </div>

            <button type="submit">
              <span v-if="isApp">Next</span>
              <span v-else>Explore OneSub</span>
            </button>
          </form>

          <div v-else class="v5-loader"></div>
        </div>

<!-------------------------------------- Sign Up: App Notifications -------------------------------------->
        <div class="auth-inner" v-if="state == 'sign-up:app-notifications'" :class="[`mq-${$mq}`]">
          <div class="intro">
            <h1><span class="highlight-yellow">Enable notifications</span></h1>
            <h2>Keep updated with the most important stories</h2>
          </div>

          <div class="app-notifications">
            <div class="icon">
              <base-svg svg="mobile" height="1.75rem" fill="black"/>
            </div>

            <div v-if="!notificationsEnabled">
              <p>Enable notifications and we'll keep you up to date with any updates to stories you've read and breaking stories.</p>
              <button @click="enableNotifications()">Enable Notifications</button>
            </div>

            <div v-else>
              <p>You're all set. We'll notify you of updates to stories and breaking news.</p>
            </div>
          </div>

          <form class="v5-form v5-form-float" @submit.prevent="hideModal()">
            <button type="submit" class="ghost-submit">Maybe Later</button>
          </form>
        </div>

<!----------------------------------------------------------------------------------------------------------------|
  Sign In
|----------------------------------------------------------------------------------------------------------------->
<!-------------------------------------- Sign In: Form -------------------------------------->
        <div class="auth-inner" v-if="state == 'sign-in'" :class="[`mq-${$mq}`]">
          <div class="intro">
            <h1><span class="highlight-yellow">Welcome back</span></h1>
            <h2>Let's get you signed in</h2>
          </div>

          <form v-if="!isWorking" class="v5-form" @submit.prevent="signIn()">
            <div class="base-msg error" v-if="responseErrorMessage">
              {{ responseErrorMessage }}
            </div>
            
            <input type="email" id="email" v-model="form.sign_in.email" placeholder="Email">
            <input type="password" id="password" v-model="form.sign_in.password" placeholder="Password">
            <p><a @click="resetPassword()">Forgot your password?</a></p>

            <button type="submit">Sign in</button>
            <div class="switch-state">
              <a @click="switchState('sign-up')">New to OneSub? Create an account</a>
            </div>
          </form>

          <div v-else class="v5-loader"></div>
        </div>

      </div>
    </transition>
    <div v-if="visibleBackground" @click="hideModal()" class="background"></div>
  </div>
</template>

<script>
  import API from "app/axios.js";

  export default {
    data() {
      return {
        state: 'sign-up',
        visible: false,
        visibleBackground: false,
        isWorking: false,

        showLastName: true,

        notificationsEnabled: false,

        features: {
          "sign-up": [
            'No ads. Ever.',
            'Unlimited stories',
            'Personalised news feed',
            'Support a small team'
          ],
          "go-pro": [
            'No ads. Ever.',
            'Unlimited stories',
            'Unlock full story timelines',
            'Support a small team'
          ]
        },

        topics: [
          { name: 'World News', byline: null, code: 'world-news', image: null, selected: false },
          { name: 'Environment', byline: null, code: 'environment', image: null, selected: false },
          { name: 'Technology', byline: null, code: 'technology', image: null, selected: false },
          { name: 'Business', byline: null, code: 'business', image: null, selected: false },
        ],

        selectedTopics: [],

        form: {
          sign_up: {},
          account: {
            details: {
              updates: false,
              updatesEmailOn: false,
              updatesEmailFreq: false,
              updatesEmailTiming: false,
            }
          },
          sign_in: {}
        },

        responseErrorMessage: null
      }
    },

    computed: {
      getEdition() {
        return this.$store.getters.getLibraryItem('edition_v3');
      },

      getNotifcations() {
        return this.$store.getters.getConfig.account.notif.email;
      },

      isApp() {
        return this.$store.getters.hasApp;
      },

      hasMini() {
        return this.$store.getters.hasMini;
      },

      documentURL() {
        return document.domain;
      },
    },

    mounted () {
      API.getHomeV3('').then(() => {
        const edition = this.getEdition;
        this.topics.splice(0, 0, { name: edition.meta.locale.name, byline: 'Country', code: edition.meta.locale.code, image: null, selected: false });

        let additional = [];
        edition.topics.splice(0, 30).forEach(topic => {
          additional.push({ name: topic.topic_name, byline: topic.meta.byline, code: topic.topic_code, image: topic.meta.image, selected: false })
        })

        this.topics = [...this.topics, ...additional];
      })

      this.EventBus.$on('notificationsEnabled', () => {
        this.notificationsEnabled = true;
      });

      this.EventBus.$on('modal:auth', (state) => {
        // Hide mobile navigation if expanded
        this.EventBus.$emit('header:hide');

        if (this.visible) {
          // If modal is displayed and switching state, delay switch to show animation
          this.switchState(state);
        } else {
          // Otherwise, just show the modal
          this.state = state;
          this.visibleBackground = true;
          this.visible = true;
        }

        // Track when state == 'sign-up' (start of onboarding flow)
        if (state == 'sign-up') {
          this.EventBus.$emit('track:event', {
            label: 'SignUp: Show',
            once: true, // default
          });
        }
      });
    },

    methods: {
      switchState(state) {
        this.visible = false;
        setTimeout(() => {
          this.isWorking = false;
          this.state = state;
          this.visible = true;
        }, 150);
      },

      hideModal() {
        this.visible = false;
        this.visibleBackground = false;
      },

      expandNameField() {
        this.showLastName = true;
      },

      handleResponseError(error) {
        switch (error.response.data.error.reason) {
          case 'exists':
            this.responseErrorMessage = `It looks like you're already signed up.  Please sign in.`;
            break;
          case 'email':
            this.responseErrorMessage = `That email address doesn't seem valid. Please check it and try again.`;
            break;
          case 'password-length':
            this.responseErrorMessage = `We couldn't accept that password.  Please provide a password at least 10 characters long.`;
            break;
          case 'missing-fields':
            this.responseErrorMessage = 'It looks like some information was missing. Please check and try again.';
            break;
          default:
            this.responseErrorMessage = `Sorry, something went wrong. Please try again.`;
            break;
        }
      },

      signUp() {
        this.responseErrorMessage = null;
        this.isWorking = true;
        API.post(`/account`, this.form.sign_up).then((response) => {   
          if (response.status.toString().substring(0,1) == 2) {
            this.$store.commit("updateAccount", response.data);
          }
          
          // Plausible tracking: (User account created)
          this.EventBus.$emit('track:event', {
            label: 'SignUp: Initial',
            once: true, // default
          });

          this.isWorking = false;
          this.switchState('sign-up:personalise');
        }).catch((error) => {
          this.isWorking = false;
          this.handleResponseError(error);
        });
      },

      selectTopic(topic) {
        // Check if already selected
        let idx = this.selectedTopics.findIndex(t => t.code == topic.code);
        if (idx >= 0) {
          // Topic exists, remove
          this.topics.splice(idx, 0);
        } else {
          this.selectedTopics.push(topic);
        }

        let tidx = this.topics.findIndex(t => t.code == topic.code);
        if (tidx >= 0) {
          this.topics[tidx].selected = !this.topics[tidx].selected;
        }
      },

      setTopicPreferences() {
        this.isWorking = true;
        this.switchState('sign-up:notifications');
      },

      updateNotificationPreferences() {
        this.responseErrorMessage = null;
        this.isWorking = true;

        API.patch(`/account`, this.form.account.details).then(() => {
          if (this.form.account.details.updatesEmailOn) {
            this.EventBus.$emit('track:event', {
							label: 'SignUp: Emails',
							once: true, // default
						});
          }

          this.EventBus.$emit('track:event', {
            label: 'SignUp: Settings',
            once: true, // default
          });

          if (this.isApp) {
            this.switchState('sign-up:app-notifications');
          } else {
            this.hideModal();
          }
          this.isWorking = false;
        }).catch((error) => {
          this.isWorking = false;
          this.responseErrorMessage = this.handleResponseError(error.response.data.error.reason);
        })
      },

      enableNotifications() {
        this.EventBus.$emit("appCallback", {
          type: 'notificationsCallback',
          data: {}
        });

        this.EventBus.$emit('track:event', {
          label: 'SignUp: Notifications',
          once: true, // default
        });
      },

      signIn() {
        this.responseErrorMessage = null;
        this.isWorking = true;

        API.post(`/account/login`, this.form.sign_in).then((response) => {     
          this.isWorking = false;

          if(response.status.toString().substring(0,1) == 2) {
            this.$store.commit("updateAccount", response.data);

            this.EventBus.$emit("appCallback", {
              type: 'authCallback',
              data: response.data.token
            });
  
          } else {
            this.responseErrorMessage = `Sorry. Something went wrong. Please try again.`;
          }

          this.hideModal();
          this.isWorking = false;
        }).catch((error) => {
          this.isWorking = false;
          if (error.response.status === 400) {
            this.responseErrorMessage = `Are those details correct? We can't find that account.`;
          } else {
            this.responseErrorMessage = `Sorry. Something went wrong. Please try again.`;
          }
        });
      },

      resetPassword() {
        this.hideModal();
        if (this.hasMini) {
          document.location = `https://${this.documentURL}/r/x/tools/pwd.php`
        } else {
          let width 	= 500;
          let height 	= 500;
          let left 	= ((window.innerWidth / 2) - (width / 2));
          let top  	= ((window.innerHeight / 2) - (height / 2));

          let pop = window.open(
            `https://${this.documentURL}/r/x/tools/pwd.php`,
            "Password reminder",
            'scrollbars=yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left,
            0
          );

          // Puts focus on the newWindow
          if (window.focus) {
            pop.focus();
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/v5.scss';

  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 9999999;
    background: rgba($neutral-500, 0.95);

    .darkmode & {
      background: rgba($neutral-300, 0.95);
    }
  }

  // Mobile
  .auth-wrapper {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba($neutral-600, 0.75);
    color: $neutral-100;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);

    .darkmode & {
      background: rgba($neutral-100, 0.75);
      color: $neutral-600;
    }

    &.mq-md, &.mq-lg {
      top: 6rem;
      bottom: auto;
      max-width: 25rem;
      height: auto;
      max-height: 45rem;
      border-radius: $border-radius;
    }

    .close {
      position: absolute;
      top: env(safe-area-inset-top);
      right: 0;
      padding: 1.5rem;
      z-index: 99;
      cursor: pointer;
      // background: rgba($neutral-600, 0.75);
      background: transparent;

      .darkmode & {
        // background: rgba($neutral-100, 0.75);
        background: transparent;
      }
    }

    .auth-inner {
      padding: 4rem 1.25rem;
      overflow-y: scroll;
      height: 100%;
      -ms-overflow-style: none;  // IE 10+
      overflow: -moz-scrollbars-none;  // Firefox
      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }

      &.mq-md, &.mq-lg {
        padding: 4rem 1.75rem 3rem 2rem;
      }

      &.overflow-wrap {
        overflow: hidden;
      }

      .switch-state {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-400;

        a {
          border-bottom: 1px solid $neutral-400;;
          padding-bottom: 2px;
        }
      }

      .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h1 {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        h2 {
          font-size: 0.9rem;
          font-weight: bold;
          color: $neutral-400;
        }

        p {
          font-size: 0.9rem;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 0 0.75rem;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-size: 0.9rem;

    svg {
      margin-right: 0.5rem;

      rect {
        fill: rgba($yellow-tone-3, 0.5);
      }

      path {
        fill: $neutral-100;

        .darkmode & {
          fill: $neutral-600;
        }
      }
    }

    div {
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
    }

    span {
      flex: 1;
    }
  }


  .v5-form {
    margin-top: 2rem;
    overflow-y: scroll;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .mq-md &, .mq-lg & {
      max-height: 30rem;
    }

    button {
      width: 100%;
      margin: 1rem 0;
    }

    .ghost-submit {
      background: rgba($neutral-400, 0.5);
    }

    p {
      font-size: 0.8rem;
      color: $neutral-400;
      text-align: center;
      line-height: 1.3;
      margin-top: 1rem;
    }
  }

  .v5-form-float {
    position: absolute;
    left: 1.25rem;
    right: 1.25rem;
    bottom: calc(env(safe-area-inset-top) + 1rem);

    .mq-md &, .mq-lg & {
      left: 1.75rem;
      right: 1.7rem;
    }
  }

  .v5-loader {
    margin-top: 4rem;
  }

  .topic-container {
    overflow-y: scroll;
    max-height: 80%;
    padding: 1rem 0 3.5rem 0;
    margin: 1.5rem 0;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .mq-md &, .mq-lg & {
      max-height: 30rem;
    }

    .topic-container-inner {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      -moz-column-gap: 1rem;
      -webkit-column-gap: 1rem;
      column-gap: 1rem;

      .topic-tag {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 1rem;
        background: $neutral-500;
        text-align: center;
        margin-bottom: 1rem;
        width: 100%;
        word-break: keep-all;
        page-break-inside: avoid;
        break-inside: avoid-column;
        border-radius: $border-radius;
        cursor: pointer;
        @include transition;

        .darkmode & {
          background: $neutral-300;
        }

        &.selected {
          background: $yellow-tone-3;
          color: $neutral-100;

          .byline {
            color: rgba($neutral-100, 0.8);
          }
        }

        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          object-fit: cover;
        }

        .name {
          font-size: 0.9rem;
          font-weight: bold;
          margin: 0.5rem 0;
        }

        .byline {
          font-size: 0.75rem;
          color: $neutral-400;
        }
      }
    }
  }

  .notifications-form {
    .form-spacer {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      border-bottom: 1px solid $neutral-500;

      .darkmode & {
        border-bottom: 1px solid $neutral-300;
      }
    }

    input[type="checkbox"] {
      box-shadow: none;
      border: 1px solid rgb(180, 185, 189);
      width: 25px;
      height: 25px;
      margin-right: 1em;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      &:checked {
        background-image: url('~@/assets/svg/tick.svg');
        background-color: rgba(241,211,2,0.05);
        border-color: $yellow-tone-3;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .checkbox-custom-label {
      margin: 0;
      width: 100%;
    }

    .checkbox-label {
      margin-top: 0;
      margin-bottom: 2rem;

      input {
        padding-top: 18px;
      }
      
      p {
        margin-top: 6px;
        font-size: 0.85rem;
      }
    }

    .checkbox-custom + p {
      @include transition;
      display: block;
      width: 100%;
      padding: 1rem;
      border-radius: $border-radius;
      font-size: 0.85rem;
      font-weight: bold;
      margin: 0;
      border: 1px solid transparent;
      background: $neutral-500;

      .darkmode & {
        background: $neutral-300;
      }
    }

    .checkbox-custom:checked + p {
      border: 1px solid $yellow-tone-3;
      color: $neutral-100;
      background: rgba($yellow-tone-3, 0.25);

      .darkmode & {
        color: $neutral-600;
      }
    }

    h4 {
      font-size: 0.9rem;
      text-align: center;
      margin: 1rem 0 0 0;
      color: $neutral-400;
    }

    .group {
      display: grid;
      grid-gap: 0.5rem;
      margin: 1rem 0 1rem 0;
    }

    .group-2 {
      grid-template-columns: 1fr 1fr;
    }

    .group-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .app-notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: rgba($yellow-tone-3, 0.1);
    margin: 4rem 0 4rem 0;
    padding: 1.5rem 1rem;
    border-radius: $border-radius;

    .icon {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $yellow-tone-3;
      margin-top: -3rem;
    }

    p {
      font-size: 0.9rem;
      margin: 1.5rem 0;
    }
  }
</style>